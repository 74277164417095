/************************************************
* TEXT
*************************************************/

	.text {
		padding: 2rem 0;

		.left p {
			margin-top: 0;
		}

		.right {
			margin-top: 3rem;

			span {
				text-transform: uppercase;
			}

			span + p {
				margin-top: 0;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					font-size: 1.8rem;
					display: inline-block;

					&::after {
						content: '//';
						margin: 0 .5rem;
					}

					&:last-of-type::after {
						content: none;
					}
				}
			}
		}
	}



/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 600px) {
		.text .right ul li {
			font-size: 1.7rem;
		}
	}

	@media (min-width: 700px) {
		.text {
			padding: 0 0 5rem 0;
		}
	}

	@media (min-width: 750px) {
		.text {

			.wrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.left {
					width: 100%;
					padding: 0 5%;
				}

				.right {
					width: 100%;
					padding: 0 5%;
				}
			}
		}
	}	

	@media (min-width: 900px) {
		.text .wrap {
			.left {
				width: 56%;
				padding: 0;
			}

			.right {
				width: 40%;
				padding: 0;
				margin: 0;
			}
		}
	}

	@media (min-width: 1024px) {
		.text {
			padding: 0 0 7rem 0;

			.wrap {
				.left {
					width: 58%;
				}

				.right {
					width: 35%;
				}
			}
		}
	}

	@media (min-width: 1200px) {
		.text {
			padding: 0 0 10rem 0;

			.wrap {
				.left {
					padding-left: 5%;
				}

				.right {
					padding-right: 5%;
				}
			}
		}
	}

	@media (min-width: 1400px) {
		.text .wrap {
			.left {
				width: 50%;
			}

			.right {
				width: 40%;
			}
		}
	}

	@media (min-width: 1500px) {
		.text {
			padding: 0 0 13rem 0;
		}
	}

	@media (min-width: 1600px) {
		.text .wrap {
			.left {
				padding-left: 8%;
			}

			.right {
				padding-right: 8%;
			}
		}
	}
/************************************************
* QUOTE
*************************************************/

    .quote {
        padding: 8rem 0 3rem 0;

        .content {
            font-family: 'Playfair Display', serif;
            font-size: 1.9rem;
            font-weight: 600;
            line-height: 1.5;
            text-align: center;
            position: relative;

            &::before {
                font-family: 'Playfair Display', serif;
                content: '”';
                font-size: 16rem;
                font-weight: 400;
                position: absolute;
                top: -12.5rem;
                left: 50%;
                transform: translateX(-50%); 
            }
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 700px) {
        .quote {
            padding: 11rem 0 5rem 0;

            .wrap {
                display: flex;
                justify-content: center;
            }

            .content {
                width: 90%;

                &::before {
                    font-size: 19rem;
                    top: -15.5rem;
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .quote {
            padding: 13rem 0 7rem 0;

            .content {
                width: 80%;
                font-size: 2.4rem;

                &::before {
                    font-size: 22rem;
                    top: -18rem;
                }
            }
        }
    }

    @media(min-width: 1200px) {
        .quote {
            padding: 14rem 0 10rem 0;

            .content {
                width: 70%;
            }
        }
    }

    @media (min-width: 1250px) {
        .quote {
            .content {
                font-size: 2.7rem;
            }
        }
    }

    @media (min-width: 1500px) {
        .quote {
            padding: 16rem 0 13rem 0;

            .content {
                font-size: 3rem;
            }
        }
    }
/************************************************
* INTRO
*************************************************/

	.intro {
		padding: 3rem 0 0;
		max-width: 95em;
		margin: 0 auto;
		text-align: center;

		.wrap {

		}

	    h1,
	    h2 {
	    	font-family: 'Playfair Display', serif;
	    	font-size: 3rem;
	    	line-height: 1.5;
	    	font-weight: bold;
	    	color: #000;
	    }

	    p:nth-of-type(1) {
	    	color: #000;
	    	font-size: 2.2em;
	    	text-align: center;
	    }

		a {
			color: $brandPrimary;
			text-decoration: none;
		}
	}


/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 700px) {
		.intro {
			padding: 5rem 0 0;
		}
	}

	@media (min-width: 1024px) {
		.intro {
			padding: 7rem 0 0;
			a:hover {
				border-bottom: 1px solid $brandPrimary;
			}
		}
	}

	@media (min-width: 1200px) {
		.intro {
			padding: 10rem 0 0;
		}
	}

	@media (min-width: 1500px) {
		.intro {
			padding: 13rem 0 0;
		}
	}

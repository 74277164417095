/************************************************
* CONTENTIMAGE
*************************************************/

	.mockUp .wrap {
		background-size: cover;
		background-position: center;
		padding-top: 52%;
	}

	.screenshot {
		padding: 3rem 0;

		.wrap {
			display: flex;
			justify-content: center;
		}

		&.tablet .wrap,
		&.mobile .wrap,
		&.desktopMobile .wrap {
			flex-wrap: wrap;
		}

		&.desktop figure {
			margin-bottom: 0;
		}

		figure {
			margin: 0;
			margin-bottom: 3rem;
			text-align: center;
			img {
				border: 1px solid #d7d7d7;
			}
		}
	}

	.divider {
		background-size: cover;
		background-position: center;
		padding-top: 35%;
	}

	/*screenshot mobile + text*/

	.screenshot.mobile.text {
		.text {
			padding: 3rem 0 4rem 0;
		}

		figure {
			margin-right: 0;
		}
	}



/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 500px) {
		.screenshot {
			&.mobile figure {
				width: 55%;
				margin: 0 20% 3rem 20%;
			}
		}

		.screenshot.mobile.text {
			figure {
				margin: 0;
			}
		}
	}

	@media (min-width: 600px) {
		.screenshot {
			&.mobile figure {
				margin: 0 5% 3rem 5%;
				width: 40%;
			}

			&.tablet figure {
				width: 80%;
			}
		}
	}

	@media (min-width: 700px) {
		.screenshot{
			padding: 5rem 0;

			&.mobile figure {
				margin: 0 5% 3rem 5%;
				width: 40%;
			}

			&.tablet figure {
				width: 70%;
			}

			&.mobile.text {
				.text {
					width: 60%;
					padding-top: 0;
					margin-right: 5%;
				}

				figure {
					width: 35%;
					text-align: left;
				}

				&.screenshotLeft {
					.text {
						margin-left: 5%;
						margin-right: 0;
					}
					figure {
						order: -1;
						text-align: right;
					}
				}

			}
		}
	}

	@media (min-width: 800px) {
		.screenshot {
			padding: 5rem 0;

			&.mobile figure,
			&.mobile figure:nth-of-type(2n) {
				width: 31%;
				margin: 0 3.5% 0 0;
			}

			&.mobile figure:nth-of-type(3n) {
				margin: 0;
			}

			&.tablet figure {
				width: 60%;
			}
		}
	}

	@media (min-width: 1024px) {
		.screenshot {

			&.desktop,
			&.mobile {
				padding: 7rem 10%;
			}

			&.desktopMobile {
				padding: 7rem 0;
			}	

			&.tablet{
				padding: 7rem 5%;
			}

			&.mobile figure,
			&.mobile figure:nth-of-type(2n) {
				width: 31%;
				margin: 0 3.5% 0 0;
			}

			&.mobile figure:nth-of-type(3n) {
				margin: 0;
			}

			&.tablet figure {
				width: 49%;
				margin-right: 2%;
				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}

			&.desktopMobile {
				.desktop {
					width: 75%;
					margin-right: 5%;
				}

				.mobile {
					width: 20%;
				}
			}
		}
	}

	@media (min-width: 1200px) {
		.screenshot{

			&.desktop,
			&.mobile {
				padding: 10rem 15%;
			}

			&.desktopMobile {
				padding: 10rem 5%;
			}

			&.tablet figure {
				width: 45%;
				margin-right: 10%;
			}
		}

		.divider {
			padding-top: 30%;
		}
	}

	@media (min-width: 1300px) {
		.screenshot {

			&.mobile figure,
			&.mobile figure:nth-of-type(2n) {
				width: 28%;
				margin-right: 8%;
			}

			&.mobile.text {
				.text {
					width: 55%;
					margin-right: 10%;
				}

				figure {
					width: 35%;
					text-align: left;
				}
			}

		}
	}

	@media (min-width: 1500px) {
		.screenshot {

			&.desktop,
			&.mobile {
				padding: 13rem 15%;
			}

			&.desktop figure img{
				max-width: 1000px;
			}

			&.desktopMobile {
				padding: 13rem 10%;
			}

			&.tablet {
				padding: 13rem 10%;
			}

			&.mobile figure,
			&.mobile figure:nth-of-type(2n) {
				width: 25%;
				margin-right: 12.5%;
			}
		}
	}

	@media (min-width: 1600px) {
		.screenshot {
			.wrap{
				padding: 0 6%;					
			}

			&.desktopMobile {

				.desktop {
					text-align: left;
				}

				.mobile {
					text-align: right;
				}
			}
		}

		.mockUp .wrap{
			padding-top: 0;
			height: 750px;
		}
	}

	@media(min-width: 1800px) {
		.screenshot.mobile.text {
			.wrap {
				justify-content: center;

				.text {
					width: 45%;
					padding-top: 0;
				}

				figure {
					width: 35%;
				}
			}
		}
	}
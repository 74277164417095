/************************************************
* CALLTOACTION
*************************************************/

	.callToAction {
		margin: 0 0 4rem 0;

	 	.text {
		 	font-size: 1.7rem;
		 	display: block;
		 	padding: 0;

		 	a {
		 		text-decoration: none;
		 		position: relative;

		 		&::after {
		 			@extend %icons;
		 			content: '\e803';
					position: absolute;
					bottom: .2rem;
					right: -2.2rem;
		 		}
	 		}
		}
	}

	.callToAction span {
		font-family: 'Playfair Display', serif;
		font-size: 1.9rem;
    line-height: 1.5;
    display: block;
    margin-bottom: 2rem;
	}

	.newsletter {
		background-color: $brandThird;
		color: $brandSecondary;
		padding: 2rem;
		margin-bottom: 1.2rem;

		a {
			color: $brandSecondary;
		}
	}

	.contact {
		background-color: $brandSecondary;
		color: white;
		padding: 2rem;

		a {
			color: white;
		}
	}



/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 600px) {
		.callToAction span {
			font-size: 2.1rem;
		}
	}

	@media (min-width: 700px) {
		.callToAction {
			margin: 0 0 5rem 0;
		}
	}

	@media (min-width: 1024px) {
		.callToAction {
			margin: 0 0 7rem 0;
		}	
	
		.callToAction .wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.callToAction span {
			font-size: 2.4rem;
		}

		.newsletter,
		.contact {
			width: 49.5%;
			margin-bottom: 0;
		}

		.newsletter a:hover,
		.contact a:hover {
			text-decoration: underline;
		}

		.callToAction .text a:hover::after {
			text-decoration: none;
		}
	}

	@media (min-width: 1200px) {
		.callToAction {
			margin: 0 0 10rem 0;
		}	
	}

	@media (min-width: 1250px) {
		.callToAction span {
			font-size: 2.7rem;
		}
	}

	@media (min-width: 1500px) {
		.callToAction {
			margin: 0 0 13rem 0;
		}	

		.callToAction span {
			font-size: 3rem;
		}
	}
/************************************************
* ICONS (fontello.com)
*************************************************/

@font-face {
  font-family: 'networker-icons';
  src: url('/fileadmin/networker.de/fonts/networker-icons.eot?57972386');
  src: url('/fileadmin/networker.de/fonts/networker-icons.eot?57972386#iefix') format('embedded-opentype'),
       url('/fileadmin/networker.de/fonts/networker-icons.woff2?57972386') format('woff2'),
       url('/fileadmin/networker.de/fonts/networker-icons.woff?57972386') format('woff'),
       url('/fileadmin/networker.de/fonts/networker-icons.ttf?57972386') format('truetype'),
       url('/fileadmin/networker.de/fonts/networker-icons.svg?57972386#networker-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icons{
    font-family: "networker-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    @extend %icons;
}

.icon-menu:before { content: '\e800'; } /* '' */
.icon-down-open-mini:before { content: '\e801'; } /* '' */
.icon-left-open-mini:before { content: '\e802'; } /* '' */
.icon-right-open-mini:before { content: '\e803'; } /* '' */
.icon-up-open-mini:before { content: '\e804'; } /* '' */
.icon-facebook:before { content: '\e806'; } /* '' */
.icon-xing:before { content: '\e805'; } /* '' */
.icon-home:before { content: '\e809'; } /* '' */
.icon-leistungen:before { content: '\e80A'; } /* '' */
.icon-team:before { content: '\e80B'; } /* '' */
.icon-agentur:before { content: '\e80c'; } /* '' */
.icon-anfrage:before { content: '\e80d'; } /* '' */
.icon-close:before { content: '\e80e'; } /* '' */
.icon-left:before { content: '\f177'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-instagram:before { content: '\e807'; } /* '' */
.icon-linkedin:before { content: '\e808'; } /* '' */

.navSocial a i::before {
  font-size: 4rem;
}
@charset "UTF-8";
/************************************************
* SETUP
*************************************************/
/* clearfix */
/* Breakpoints */
/* fluid type */
/* grid stuff */
/************************************************
* theme Settings
*************************************************/
/* flexNav breakpoint (min-width) */
/*****************
* Colors
******************/
/************************************************
* ICONS (fontello.com)
*************************************************/
@font-face {
  font-family: 'networker-icons';
  src: url("/fileadmin/networker.de/fonts/networker-icons.eot?57972386");
  src: url("/fileadmin/networker.de/fonts/networker-icons.eot?57972386#iefix") format("embedded-opentype"), url("/fileadmin/networker.de/fonts/networker-icons.woff2?57972386") format("woff2"), url("/fileadmin/networker.de/fonts/networker-icons.woff?57972386") format("woff"), url("/fileadmin/networker.de/fonts/networker-icons.ttf?57972386") format("truetype"), url("/fileadmin/networker.de/fonts/networker-icons.svg?57972386#networker-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before, .back a::after, .callToAction .text a::after, .scrollTop:after, .select2-container--default .select2-selection--single .select2-selection__arrow b::after, .textStandard ul li::before {
  font-family: "networker-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none; }

.icon-menu:before {
  content: '\e800'; }

/* '' */
.icon-down-open-mini:before {
  content: '\e801'; }

/* '' */
.icon-left-open-mini:before {
  content: '\e802'; }

/* '' */
.icon-right-open-mini:before {
  content: '\e803'; }

/* '' */
.icon-up-open-mini:before {
  content: '\e804'; }

/* '' */
.icon-facebook:before {
  content: '\e806'; }

/* '' */
.icon-xing:before {
  content: '\e805'; }

/* '' */
.icon-home:before {
  content: '\e809'; }

/* '' */
.icon-leistungen:before {
  content: '\e80A'; }

/* '' */
.icon-team:before {
  content: '\e80B'; }

/* '' */
.icon-agentur:before {
  content: '\e80c'; }

/* '' */
.icon-anfrage:before {
  content: '\e80d'; }

/* '' */
.icon-close:before {
  content: '\e80e'; }

/* '' */
.icon-left:before {
  content: '\f177'; }

/* '' */
.icon-right:before {
  content: '\f178'; }

/* '' */
.icon-instagram:before {
  content: '\e807'; }

/* '' */
.icon-linkedin:before {
  content: '\e808'; }

/* '' */
.navSocial a i::before {
  font-size: 4rem; }

/************************************************
* Base Definitions
*************************************************/
/************************************************
* FONT
*************************************************/
/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans"), local("PTSans-Regular"), url("/fileadmin/networker.de/fonts/pt-sans-v9-latin-regular.woff2") format("woff2"), url("/fileadmin/networker.de/fonts/pt-sans-v9-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold"), local("PTSans-Bold"), url("/fileadmin/networker.de/fonts/pt-sans-v9-latin-700.woff2") format("woff2"), url("/fileadmin/networker.de/fonts/pt-sans-v9-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* playfair-display-regular - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Playfair Display Regular"), local("PlayfairDisplay-Regular"), url("/fileadmin/networker.de/fonts/playfair-display-v13-latin-regular.woff2") format("woff2"), url("/fileadmin/networker.de/fonts/playfair-display-v13-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/************************************************
* Base Font Setting
*************************************************/
html {
  font-size: 62.5%; }

body {
  font-family: 'PT Sans', sans-serif;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************************************************
 * TYPO
 ************************************************/
h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  line-height: 1.5;
  color: white; }
  h1 {
    font-size: 25px; }
    @media screen and (min-width: 320px) {
      h1 {
        font-size: calc(25px + 35 * ((100vw - 320px) / 1280)); } }
    @media screen and (min-width: 1600px) {
      h1 {
        font-size: 60px; } }

h2 {
  font-family: 'Playfair Display', serif;
  font-size: 1.9rem;
  line-height: 1.5; }

h3 {
  font-family: 'Playfair Display', serif;
  font-size: 1.9rem;
  line-height: 1.5; }

p {
  font-family: 'PT Sans', sans-serif;
  font-size: 1.9rem;
  line-height: 1.5;
  margin: 0 0 1.5em; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 1024px) {
  h2 {
    font-size: 2.4rem; }
  h3 {
    font-size: 2.1rem; } }

@media (min-width: 1250px) {
  h2 {
    font-size: 2.7rem; }
  h3 {
    font-size: 2.3rem; } }

@media (min-width: 1500px) {
  h2 {
    font-size: 3rem; }
  h3 {
    font-size: 2.5rem; } }

/************************************************
* ICONS (fontello.com)
*************************************************/
@font-face {
  font-family: 'networker-icons';
  src: url("/fileadmin/networker.de/fonts/networker-icons.eot?57972386");
  src: url("/fileadmin/networker.de/fonts/networker-icons.eot?57972386#iefix") format("embedded-opentype"), url("/fileadmin/networker.de/fonts/networker-icons.woff2?57972386") format("woff2"), url("/fileadmin/networker.de/fonts/networker-icons.woff?57972386") format("woff"), url("/fileadmin/networker.de/fonts/networker-icons.ttf?57972386") format("truetype"), url("/fileadmin/networker.de/fonts/networker-icons.svg?57972386#networker-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before, .back a::after, .callToAction .text a::after, .scrollTop:after, .select2-container--default .select2-selection--single .select2-selection__arrow b::after, .textStandard ul li::before {
  font-family: "networker-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none; }

.icon-menu:before {
  content: '\e800'; }

/* '' */
.icon-down-open-mini:before {
  content: '\e801'; }

/* '' */
.icon-left-open-mini:before {
  content: '\e802'; }

/* '' */
.icon-right-open-mini:before {
  content: '\e803'; }

/* '' */
.icon-up-open-mini:before {
  content: '\e804'; }

/* '' */
.icon-facebook:before {
  content: '\e806'; }

/* '' */
.icon-xing:before {
  content: '\e805'; }

/* '' */
.icon-home:before {
  content: '\e809'; }

/* '' */
.icon-leistungen:before {
  content: '\e80A'; }

/* '' */
.icon-team:before {
  content: '\e80B'; }

/* '' */
.icon-agentur:before {
  content: '\e80c'; }

/* '' */
.icon-anfrage:before {
  content: '\e80d'; }

/* '' */
.icon-close:before {
  content: '\e80e'; }

/* '' */
.icon-left:before {
  content: '\f177'; }

/* '' */
.icon-right:before {
  content: '\f178'; }

/* '' */
.icon-instagram:before {
  content: '\e807'; }

/* '' */
.icon-linkedin:before {
  content: '\e808'; }

/* '' */
.navSocial a i::before {
  font-size: 4rem; }

/************************************************
* natural box-sizing
*************************************************/
*, *:after, *:before {
  box-sizing: border-box; }

/************************************************
* responsive images
*************************************************/
/************************************************
* general CSS Stylings
*************************************************/
::-moz-selection {
  background-color: #85c800; }

::selection {
  background-color: #85c800; }

.wrap {
  width: 94%;
  margin-left: auto;
  margin-right: auto; }

.noBottomPadding {
  padding-bottom: 0 !important; }

.noBorder img {
  border: none !important; }

@media (min-width: 1600px) {
  .wrap {
    max-width: 1500px; } }

/* hide elements first */
.hide {
  opacity: 0; }

/* Fade In Up Effect */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 2em, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUp {
  animation-name: fadeInUp; }

.fadeInUp-active {
  animation: fadeInUp 1s ease forwards; }

/* fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

.fadeIn-active {
  animation: fadeIn 2s .8s forwards; }

/* fadeInDown */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInDown {
  animation-name: fadeInDown; }

.fadeInDown-active {
  animation: fadeInDown .7s 1.5s ease forwards; }

/* fadeInRight */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInRight {
  animation-name: fadeInRight; }

.fadeInRight-active {
  animation: fadeInRight 1s forwards; }

/* fadeInRightNav */
@keyframes fadeInRightNav {
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInRightNav {
  animation-name: fadeInRightNav; }

/* fadeInLeft */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeft {
  animation-name: fadeInLeft; }

.fadeInLeft-active {
  animation: fadeInLeft 1s forwards; }

/* bounce */
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0); }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0); }
  90% {
    transform: translate3d(0, -4px, 0); } }

.bounce {
  animation-name: bounce;
  transform-origin: center bottom; }

/************************************************
* Library's (optional)
*************************************************/
/**********************************
* Flexnav Slide Stylings
**********************************/
ul.flexnav {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  transition: none;
  -webkit-transform-style: preserve-3d;
  max-height: 0;
  overflow: hidden;
  /* generelle Stylings für alle Ebenen */ }
  ul.flexnav.opacity {
    opacity: 0; }
  ul.flexnav.flexnav-show {
    max-height: 2000px;
    opacity: 1;
    transition: all .5s ease-in-out; }
  ul.flexnav ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  ul.flexnav li a {
    display: block;
    text-decoration: none; }

.slideNavWrapper {
  position: fixed;
  right: -50rem;
  top: 0;
  bottom: 0;
  background-color: white;
  padding: 1.5rem 7rem 1.5rem 1.5rem;
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 1000; }
  .slideNavWrapper .menuButton::before {
    content: 'x';
    font-family: 'PT Sans', sans-serif;
    font-size: 2em; }

.slideNavOverlay,
.slideNavButtons {
  opacity: 0;
  transition: opacity 0.2s ease; }

.navOpen .slideNavWrapper {
  left: 0;
  right: 0;
  opacity: 1; }

.navOpen .slideNavOverlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 1;
  z-index: 3; }

.navOpen .slideNavButtons {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  padding: 1rem;
  opacity: 1; }

/************************************************
* RESPONSIVE
*************************************************/
@media all and (min-width: 400px) {
  .navOpen .slideNavWrapper {
    left: auto;
    width: 40rem; } }

@media all and (min-width: 500px) {
  .navOpen .slideNavWrapper {
    width: 50rem;
    padding: 2.5rem 8rem 2.5rem 2.5rem; } }

@media all and (min-width: 900px) {
  ul.flexnav {
    position: relative;
    overflow: visible;
    max-height: none;
    z-index: 2; }
    ul.flexnav:after {
      content: "";
      display: table;
      clear: both; }
    ul.flexnav.opacity {
      opacity: 1; }
  /* Stylings erste Ebene (nicht vererbt) */
  ul.level1 > li {
    position: relative;
    float: left;
    overflow: visible; }
  /* Stylings zweite Ebene (nicht vererbt) */
  ul.level2 {
    position: absolute;
    top: auto;
    left: 0;
    display: none; }
    ul.level2 > li {
      position: relative; }
      ul.level2 > li > a {
        white-space: nowrap; }
  /* Stylings dritte Ebene (nicht vererbt) */
  ul.level3 {
    position: absolute;
    top: 0;
    left: 100%;
    display: none; }
    ul.level3 > li {
      position: relative; }
      ul.level3 > li a {
        white-space: nowrap; }
  .menuButton {
    display: none; } }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/************************************************
* CONTENT
*************************************************/
/************************************************
* General CONTENT Stylings
*************************************************/
button,
.button {
  display: inline-block;
  font-family: 'PT Sans', sans-serif;
  font-size: 1.7rem;
  line-height: 1.5;
  background-color: #242424;
  color: white;
  border: 1px solid #242424;
  border-radius: 3px;
  padding: 1.3rem 2.7rem;
  margin: 4rem 3rem 0 0;
  text-decoration: none;
  transition: all .3s ease-in; }

.back {
  padding: 0 0 6rem 0;
  text-align: center; }
  .back a {
    font-family: 'Playfair Display', serif;
    font-size: 1.9rem;
    text-decoration: none;
    color: #242424;
    transition: all .3s ease-in; }
    .back a::after {
      content: '\f178';
      color: #85c800;
      margin-left: .7rem;
      transition: all .3s ease-in; }

.cta-button {
  padding: 0 0 6rem 0;
  text-align: center; }
  .cta-button a {
    font-family: 'PT Sans', sans-serif;
    font-size: 1.9rem;
    text-decoration: none;
    color: white;
    background-color: #85c800;
    font-weight: bold;
    padding: 0.5em 1em;
    display: inline-block; }

body > div {
  opacity: 1;
  transition: opacity .25s ease-in; }

p a,
.form-check-label a {
  color: #639500;
  text-decoration: none;
  transition: color 200ms linear; }
  p a:hover,
  .form-check-label a:hover {
    border-bottom: 1px solid #85c800; }

.attentionBox {
  position: fixed;
  text-align: center;
  width: 100%;
  padding: 0 5%;
  z-index: 100;
  bottom: 10%; }
  .attentionBox > div {
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-weight: bold;
    margin: 0 auto;
    max-width: 1280px;
    padding: 1em 2em; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 700px) {
  .back {
    padding: 0 0 8rem 0; } }

@media (min-width: 1025px) {
  button:hover,
  .button:hover {
    border: 1px solid #85c800;
    color: #242424;
    background-color: white; }
  .back a {
    font-size: 2.1rem; }
    .back a:hover::after {
      transform: translateX(30%); } }

@media (min-width: 1200px) {
  .back {
    padding: 0 0 10rem 0; } }

@media (min-width: 1250px) {
  .back a {
    font-size: 2.5rem; }
    .back a::after {
      margin-left: 1.2rem; } }

@media (min-width: 1500px) {
  .back {
    padding: 0 0 13rem 0; } }

/* responsive new button elemen */
@media (min-width: 700px) {
  .cta-button {
    padding: 0 0 8rem 0; } }

@media (min-width: 1025px) {
  .cta-button a:hover {
    background-color: black; }
  .cta-button a {
    font-size: 2.1rem; } }

@media (min-width: 1200px) {
  .cta-button {
    padding: 0 0 10rem 0; } }

@media (min-width: 1250px) {
  .cta-button a {
    font-size: 2.5rem; } }

@media (min-width: 1500px) {
  .cta-button {
    padding: 0 0 13rem 0; } }

/************************************************
* INTRO
*************************************************/
.intro {
  padding: 3rem 0 0;
  max-width: 95em;
  margin: 0 auto;
  text-align: center; }
  .intro h1,
  .intro h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    line-height: 1.5;
    font-weight: bold;
    color: #000; }
  .intro p:nth-of-type(1) {
    color: #000;
    font-size: 2.2em;
    text-align: center; }
  .intro a {
    color: #85c800;
    text-decoration: none; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 700px) {
  .intro {
    padding: 5rem 0 0; } }

@media (min-width: 1024px) {
  .intro {
    padding: 7rem 0 0; }
    .intro a:hover {
      border-bottom: 1px solid #85c800; } }

@media (min-width: 1200px) {
  .intro {
    padding: 10rem 0 0; } }

@media (min-width: 1500px) {
  .intro {
    padding: 13rem 0 0; } }

/************************************************
* CONTENT
*************************************************/
/************************************************
* FEATUREBOXES
*************************************************/
@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.featureBoxes {
  margin-top: 15rem;
  padding: 0 0 3rem 0; }

a.feature {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 1.2rem; }
  a.feature .background {
    background-size: cover;
    background-position: center;
    transition: transform 1s, filter 1s; }
    a.feature .background::before {
      content: '';
      /* background:#000; */
      opacity: .2;
      transition: opacity 400ms ease-in;
      padding-top: 58%;
      display: block; }
  a.feature.hide {
    opacity: 0; }
  a.feature.fadeIn-active {
    animation: fadeIn 1.5s forwards; }

a.feature .text {
  color: white;
  text-decoration: none;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  font-family: 'Playfair Display', serif;
  font-size: 1.9rem;
  line-height: 1.5;
  transition: opacity .3s ease-in;
  padding: 0; }
  a.feature .text span {
    display: block;
    font-family: 'PT Sans', sans-serif;
    font-size: 1.6rem;
    text-transform: uppercase; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 600px) {
  .feature .text {
    font-size: 1.9rem; } }

@media (min-width: 700px) {
  .featureBoxes {
    padding: 0 0 5rem 0; } }

@media (min-width: 800px) {
  .featureBoxes .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  a.feature {
    margin-bottom: 1rem; }
    a.feature .background::before {
      padding-top: 0;
      min-height: 40rem; }
    a.feature.feature:nth-of-type(1), a.feature.feature:nth-of-type(2), a.feature.feature:nth-of-type(9), a.feature.feature:nth-of-type(10), a.feature.feature:nth-of-type(17), a.feature.feature:nth-of-type(18) {
      width: 49.5%; }
    a.feature.feature:nth-of-type(3), a.feature.feature:nth-of-type(11) {
      width: 65%; }
    a.feature.feature:nth-of-type(4), a.feature.feature:nth-of-type(12) {
      width: 34%; }
    a.feature.feature:nth-of-type(5), a.feature.feature:nth-of-type(8), a.feature.feature:nth-of-type(13), a.feature.feature:nth-of-type(16) {
      width: 40%; }
    a.feature.feature:nth-of-type(6), a.feature.feature:nth-of-type(7), a.feature.feature:nth-of-type(14), a.feature.feature:nth-of-type(15) {
      width: 59%; }
    a.feature.fadeIn-active:nth-of-type(2n) {
      animation: fadeIn 2s .25s forwards; }
    a.feature.fadeIn-active:nth-of-type(9), a.feature.fadeIn-active:nth-of-type(11) {
      animation: fadeIn 2s .5s forwards; }
    a.feature.fadeIn-active:nth-of-type(12) {
      animation: fadeIn 2s .75s forwards; } }

@media (min-width: 1024px) {
  .featureBoxes {
    padding: 0 0 7rem 0; }
  a.feature .text {
    font-size: 2.1rem; } }

@media (min-width: 1025px) {
  a.feature {
    overflow: hidden; }
    a.feature:hover .background {
      transform: scale(1.1) rotate(2deg);
      filter: blur(1px); }
      a.feature:hover .background::before {
        opacity: .4; } }

@media (min-width: 1200px) {
  .featureBoxes {
    padding: 0 0 10rem 0; } }

@media (min-width: 1250px) {
  a.feature .text {
    font-size: 2.3rem;
    bottom: 2.5rem;
    left: 2.5rem;
    right: 2.5rem; }
  a.feature .background::before {
    min-height: 45rem; } }

@media (min-width: 1500px) {
  .featureBoxes {
    padding: 0 0 13rem 0; }
  a.feature {
    margin-bottom: 1.5rem; }
    a.feature .text {
      font-size: 2.5rem; } }

/************************************************
* CALLTOACTION
*************************************************/
.callToAction {
  margin: 0 0 4rem 0; }
  .callToAction .text {
    font-size: 1.7rem;
    display: block;
    padding: 0; }
    .callToAction .text a {
      text-decoration: none;
      position: relative; }
      .callToAction .text a::after {
        content: '\e803';
        position: absolute;
        bottom: .2rem;
        right: -2.2rem; }

.callToAction span {
  font-family: 'Playfair Display', serif;
  font-size: 1.9rem;
  line-height: 1.5;
  display: block;
  margin-bottom: 2rem; }

.newsletter {
  background-color: #ededed;
  color: #242424;
  padding: 2rem;
  margin-bottom: 1.2rem; }
  .newsletter a {
    color: #242424; }

.contact {
  background-color: #242424;
  color: white;
  padding: 2rem; }
  .contact a {
    color: white; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 600px) {
  .callToAction span {
    font-size: 2.1rem; } }

@media (min-width: 700px) {
  .callToAction {
    margin: 0 0 5rem 0; } }

@media (min-width: 1024px) {
  .callToAction {
    margin: 0 0 7rem 0; }
  .callToAction .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .callToAction span {
    font-size: 2.4rem; }
  .newsletter,
  .contact {
    width: 49.5%;
    margin-bottom: 0; }
  .newsletter a:hover,
  .contact a:hover {
    text-decoration: underline; }
  .callToAction .text a:hover::after {
    text-decoration: none; } }

@media (min-width: 1200px) {
  .callToAction {
    margin: 0 0 10rem 0; } }

@media (min-width: 1250px) {
  .callToAction span {
    font-size: 2.7rem; } }

@media (min-width: 1500px) {
  .callToAction {
    margin: 0 0 13rem 0; }
  .callToAction span {
    font-size: 3rem; } }

/************************************************
* SCROLLTOP
*************************************************/
.scrollTop {
  background-color: white;
  border: 2px solid #242424;
  display: none;
  width: 4rem;
  height: 4rem;
  position: fixed;
  right: 2%;
  bottom: 9%;
  border-radius: .3rem;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  cursor: pointer; }
  .scrollTop:after {
    position: absolute;
    content: '\e804';
    font-size: 3rem;
    color: #242424;
    left: .3rem;
    bottom: .1rem; }
  .scrollTop:hover {
    opacity: 1; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 1300px) {
  .scrollTop {
    right: 3%;
    bottom: 15%; } }

/************************************************
* ICONTEXT
*************************************************/
.iconText {
  padding: 0 0 3rem 0; }
  .iconText h2 {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 3rem;
    width: 95%; }
  .iconText span {
    font-family: 'Playfair Display', serif;
    font-size: 1.9rem;
    line-height: 1.5;
    display: block;
    margin-bottom: 4rem;
    text-align: center; }
  .iconText .content {
    text-align: center;
    margin-bottom: 4.5rem; }
    .iconText .content h3 {
      color: #85c800;
      margin: 1rem 0; }
    .iconText .content a {
      text-decoration: none;
      color: #85c800; }
      .iconText .content a:visited {
        text-decoration: none; }
  .iconText ul {
    padding: 0;
    margin: 0; }
    .iconText ul li {
      font-size: 1.7rem;
      line-height: 1.5;
      list-style-type: none; }
  .iconText.fourColumns .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .iconText.fourColumns .content {
    width: 60%;
    margin-bottom: 4rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .iconText.fourColumns .wrap {
    justify-content: flex-start; }
  .iconText.fourColumns .content {
    width: 45%;
    margin-right: 10%;
    padding: 0 2%; }
    .iconText.fourColumns .content:nth-of-type(2n) {
      margin-right: 0; } }

@media (min-width: 600px) {
  .iconText.fourColumns .content {
    padding: 0 5%;
    margin-bottom: 5rem; } }

@media (min-width: 700px) {
  .iconText {
    padding: 0 0 5rem 0; }
    .iconText .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start; }
    .iconText .content {
      width: 47%;
      margin: 0 3% 6rem 0; }
      .iconText .content:nth-of-type(2n) {
        margin: 0 0 6rem 0; }
    .iconText.fourColumns .content,
    .iconText.fourColumns .content:nth-of-type(2n) {
      width: 30%;
      margin-right: 5%;
      padding: 0 2%; }
    .iconText.fourColumns .content:nth-of-type(3n) {
      margin-right: 0; } }

@media (min-width: 1024px) {
  .iconText {
    padding: 0 0 7rem 0; }
    .iconText h2 {
      margin-bottom: 5rem; }
    .iconText .wrap span {
      font-size: 2.1rem;
      margin: 0 auto; }
    .iconText .content {
      width: 32%;
      margin: 0 2% 8rem 0; }
      .iconText .content:nth-of-type(2n) {
        margin: 0 2% 8rem 0; }
      .iconText .content:nth-of-type(3n) {
        margin: 0 0 8rem 0; }
    .iconText.fourColumns .content,
    .iconText.fourColumns .content:nth-of-type(2n),
    .iconText.fourColumns .content:nth-of-type(3n) {
      width: 20%;
      margin: 0 6.666667% 8rem 0;
      padding: 0; }
    .iconText.fourColumns .content:nth-of-type(4n) {
      margin: 0 0 8rem 0; } }

@media (min-width: 1200px) {
  .iconText {
    padding: 0 0 10rem 0; }
    .iconText .content {
      padding: 0 3%; }
    .iconText.fourColumns {
      padding: 0 2%; } }

@media (min-width: 1250px) {
  .iconText .wrap span {
    font-size: 2.5rem; } }

@media (min-width: 1300px) {
  .iconText.fourColumns .content,
  .iconText.fourColumns .content:nth-of-type(2n),
  .iconText.fourColumns .content:nth-of-type(3n) {
    width: 18%;
    margin-right: 9.33333%;
    padding: 0; }
  .iconText.fourColumns .content:nth-of-type(4n) {
    margin-right: 0; } }

@media (min-width: 1500px) {
  .iconText {
    padding: 0 0 13rem 0; }
  .iconText.fourColumns .content,
  .iconText.fourColumns .content:nth-of-type(2n),
  .iconText.fourColumns .content:nth-of-type(3n) {
    width: 16%;
    margin: 0 12% 10rem 0;
    padding: 0; }
  .iconText.fourColumns .content:nth-of-type(4n) {
    margin-right: 0; } }

@media (min-width: 1024px) {
  .iconText a:hover {
    border-bottom: 1px solid #85c800; } }

/************************************************
* TEAM
*************************************************/
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.entry {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 4rem; }
  .entry .image {
    width: 90%;
    margin-bottom: 2rem;
    align-self: center; }
    .entry .image.fadeInLeft-active {
      animation: fadeInLeft 1s forwards; }
  .entry .text {
    width: 90%;
    margin: 0;
    padding: 0;
    align-self: center; }
    .entry .text.fadeInRight-active {
      animation: fadeInRight 1s forwards; }

.entry:nth-child(2n) .image.fadeInLeft-active {
  animation: fadeInRight 1s forwards; }

.entry:nth-child(2n) .text.fadeInRight-active {
  animation: fadeInLeft 1s forwards; }

.image img {
  width: 100%;
  height: auto;
  display: block; }

.text span {
  font-size: 1.6rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0; }

.text h3 {
  margin: .5rem 0 0 0; }

.text p {
  margin-bottom: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 400px) {
  .entry .text,
  .entry .image {
    width: 80%; } }

@media (min-width: 500px) {
  .entry .text,
  .entry .image {
    width: 60%; } }

@media (min-width: 650px) {
  .entry {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4rem; }
    .entry .text {
      margin: 0 0 0 1.5rem;
      width: 47%; }
    .entry .image {
      margin: 0 1.5rem 0 0;
      width: 47%; }
  .entry:nth-of-type(2n) .text {
    order: -1;
    text-align: right;
    margin: 0 1.5rem 0 0; }
  .entry:nth-of-type(2n) .image {
    margin: 0 0 0 1.5rem; } }

@media (min-width: 800px) {
  .entry {
    margin-bottom: 5rem; }
    .entry .text,
    .entry .image {
      width: 40%; } }

@media (min-width: 950px) {
  .entry .text,
  .entry .image {
    width: 32%; } }

@media (min-width: 1100px) {
  .entry .text,
  .entry .image {
    width: 28%; } }

@media (min-width: 1300px) {
  .entry .text,
  .entry .image {
    width: 25%; } }

/************************************************
* IMAGETEXT
*************************************************/
.imageText .content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem; }
  .imageText .content h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem; }
  .imageText .content .text,
  .imageText .content .image {
    margin-bottom: 2rem;
    width: 100%; }
  .imageText .content .text.fadeInRight-active {
    animation: fadeInRight 1s forwards; }
  .imageText .content .text p:first-of-type {
    margin-top: 0; }
  .imageText .content .text a {
    color: #242424;
    font-weight: 700;
    text-decoration: none;
    transition: color .2s ease-in; }
    .imageText .content .text a:hover {
      color: #85c800; }
  .imageText .content .image.fadeInLeft-active {
    animation: fadeInLeft 1s forwards; }
  .imageText .content .image img {
    display: block;
    height: auto;
    border: 1px solid #242424; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 700px) {
  .imageText.noBottomPadding .content .text {
    padding: 0 0 0 0; }
  .imageText.noMargin {
    margin: 0 0 0 0; }
    .imageText.noMargin .content {
      margin-bottom: 0; }
  .imageText.minMargin {
    margin: 0 0 2.5rem 0; }
  .imageText.maxMargin {
    margin: 0 0 5rem 0; }
  .imageText {
    margin: 0 0 5rem 0; }
    .imageText .content .image {
      width: 75%; } }

@media (min-width: 900px) {
  .imageText.noMargin {
    margin: 0 0 0 0; }
    .imageText.noMargin .content {
      margin-bottom: 0; }
  .imageText .content {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4rem; }
    .imageText .content .text {
      margin: 0 0 0 1.5rem;
      width: 48%; }
    .imageText .content .image {
      margin: 0 1.5rem 0 0;
      width: 48%; } }

@media (min-width: 1024px) {
  .imageText {
    margin: 0 0 7rem 0; }
    .imageText .content h2 {
      margin-bottom: 5rem; }
  .imageText.noBottomPadding .content .text {
    padding: 0 0 0 0; }
  .imageText.noMargin {
    margin: 0 0 0 0; }
    .imageText.noMargin .content {
      margin-bottom: 0; }
  .imageText.minMargin {
    margin: 0 0 3.5rem 0; }
  .imageText.maxMargin {
    margin: 0 0 7rem 0; } }

@media (min-width: 1200px) {
  .imageText {
    margin: 0 0 10rem 0; }
  .imageText.noBottomPadding .content .text {
    padding: 0 0 0 0; }
  .imageText.noMargin {
    margin: 0 0 0 0; }
    .imageText.noMargin .content {
      margin-bottom: 0; }
  .imageText.minMargin {
    margin: 0 0 5rem 0; }
  .imageText.maxMargin {
    margin: 0 0 10rem 0; } }

@media (min-width: 1400px) {
  .imageText .content .image,
  .imageText .content .text {
    width: 40%; }
  .imageText .content p {
    margin-top: 3rem; } }

@media (min-width: 1500px) {
  .imageText.noBottomPadding .content .text {
    padding: 0 0 0 0; }
  .imageText.noMargin {
    margin: 0 0 0 0; }
    .imageText.noMargin .content {
      margin-bottom: 0; }
  .imageText.minMargin {
    margin: 0 0 6.5rem 0; }
  .imageText,
  .imageText.maxMargin {
    margin: 0 0 13rem 0; } }

/************************************************
* IMAGETEXT
*************************************************/
.address .content {
  margin-bottom: 2rem; }
  .address .content .addressText {
    margin-bottom: 2rem; }
  .address .content .image {
    margin-bottom: 2rem; }
    .address .content .image img {
      border: 1px solid #242424; }
  .address .content .addressText address {
    font-size: 1.7rem;
    line-height: 1.5;
    font-style: normal; }
    .address .content .addressText address .org {
      font-weight: 700; }
    .address .content .addressText address .postcode,
    .address .content .addressText address .locality {
      display: inline-block; }
    .address .content .addressText address .tel,
    .address .content .addressText address .email,
    .address .content .addressText address .url {
      font-size: 1.7rem;
      text-decoration: none;
      color: #242424;
      font-weight: 400; }
    .address .content .addressText address span {
      font-size: 1.7rem;
      line-height: 1.5;
      text-transform: none;
      text-decoration: none;
      color: #242424; }
      .address .content .addressText address span.postcode {
        margin-right: 8px; }
    .address .content .addressText address a.button {
      background-color: white;
      color: black; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 700px) {
  .address {
    margin: 0 0 5rem 0; }
    .address .content .image {
      width: 75%; } }

@media (min-width: 900px) {
  .address .content {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem; }
    .address .content .addressText {
      margin: 0 0 0 1.5rem;
      width: 48%; }
    .address .content .image {
      margin: 0 1.5rem 0 0;
      width: 48%; } }

@media (min-width: 1024px) {
  .address {
    margin: 0 0 7rem 0; } }

@media (min-width: 1200px) {
  .address {
    margin: 0 0 10rem 0; }
    .address .content a.button {
      margin: 4rem 3rem 0 0; } }

@media (min-width: 1400px) {
  .address .content .image,
  .address .content .addressText {
    width: 40%; }
  .address .content p {
    margin-top: 3rem; } }

@media (min-width: 1500px) {
  .address {
    margin: 0 0 13rem 0; } }

@media (min-width: 1600px) {
  .address .content a.button {
    margin-top: 5rem; } }

/************************************************
* formLayout10
*************************************************/
.formLayout10 {
  padding: 3rem 0;
  /* static text */
  /* immer um ein form element (label + div) */
  /* Grid Row */
  /* remove prop. stylings */
  /* Label allgemein */
  /* Fieldset */
  /* Input & Textarea */
  /* checkboxen */
  /* Submit */ }
  .formLayout10 h1,
  .formLayout10 h2 {
    margin-top: 0; }
  .formLayout10 h2 + div {
    margin-bottom: 4rem; }
  .formLayout10 .form-group {
    margin-bottom: 3rem; }
  .formLayout10 .row {
    width: 100%;
    margin-bottom: 2rem; }
    .formLayout10 .row + .row h2 {
      margin-top: 3rem; }
  .formLayout10 input[type="text"],
  .formLayout10 input[type="submit"],
  .formLayout10 input[type="file"],
  .formLayout10 textarea {
    -webkit-appearance: none; }
  .formLayout10 label {
    width: 100%;
    font-size: 1.8rem;
    display: block;
    margin-bottom: 1rem; }
  .formLayout10 fieldset {
    margin: 0 0 2rem 0;
    padding: 0;
    border: 0; }
    .formLayout10 fieldset legend {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 2rem;
      padding: 0; }
    .formLayout10 fieldset > .form-group,
    .formLayout10 fieldset .clearfix {
      margin-bottom: 1rem; }
  .formLayout10 .input:not(.checkbox) {
    width: 100%; }
    .formLayout10 .input:not(.checkbox) input,
    .formLayout10 .input:not(.checkbox) select,
    .formLayout10 .input:not(.checkbox) textarea {
      font-family: 'PT Sans', sans-serif;
      border: 1px solid #242424;
      border-radius: 3px;
      font-size: 1.5rem;
      padding: .8rem;
      width: 100%; }
      .formLayout10 .input:not(.checkbox) input:focus,
      .formLayout10 .input:not(.checkbox) select:focus,
      .formLayout10 .input:not(.checkbox) textarea:focus {
        border: 1px solid #85c800; }
    .formLayout10 .input:not(.checkbox) textarea {
      height: 20rem; }
  .formLayout10 label.form-check-label span {
    display: inline-block;
    margin-left: .8rem; }
  .formLayout10 .input.checkbox {
    width: 100%; }
  .formLayout10 .csc-form-element-fileupload {
    position: relative;
    cursor: pointer; }
    .formLayout10 .csc-form-element-fileupload label {
      width: 100%;
      margin-bottom: 9rem;
      cursor: pointer; }
      .formLayout10 .csc-form-element-fileupload label::after {
        content: '';
        position: absolute;
        border: 1px solid #242424;
        border-radius: 3px;
        width: 100%;
        right: 0;
        top: 3.3rem;
        bottom: 0;
        height: 3.8rem;
        cursor: pointer; }
      .formLayout10 .csc-form-element-fileupload label::before {
        content: 'Datei auswählen';
        position: absolute;
        top: 5.1rem;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer; }
    .formLayout10 .csc-form-element-fileupload input[type="file"] {
      display: none; }
  .formLayout10 .actions {
    width: 100%;
    text-align: right; }
    .formLayout10 .actions .btn-group {
      text-align: left; }
    .formLayout10 .actions span {
      display: inline-block; }
    .formLayout10 .actions button {
      background: transparent none;
      color: black;
      cursor: pointer;
      margin: 0; }

/************************************************
 * Select 2 Stylings überschreiben
 ************************************************/
.select2-container--default {
  font-size: 1.5rem;
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  /* Hintergrund für aktive Auswahl*/
  /* Hintergrund für gehoverte Auswahl*/ }
  .select2-container--default .select2-selection--single {
    font-size: 1.5rem;
    padding: .4rem .4rem .3rem .4rem;
    padding-right: 4rem;
    font-family: 'PT Sans', sans-serif;
    background-color: white;
    border-radius: 3px;
    border: 1px solid #242424;
    height: auto; }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: auto;
      bottom: 0;
      width: 4rem;
      top: 0;
      right: 0;
      border: 1px solid #242424;
      background-color: #242424;
      border-left: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px; }
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: none; }
        .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
          content: '\e801';
          position: absolute;
          top: -.8rem;
          left: -.8rem;
          font-size: 2.5rem;
          color: white; }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      font-size: 1.8rem;
      padding: 0; }
      .select2-container--default .select2-selection--single .select2-selection__rendered li:before {
        content: ''; }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: none; }
    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::after {
      content: '\e804'; }
  .select2-container--default .select2-results__option {
    padding: 1rem; }
  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #242424 !important;
    color: white !important; }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: white;
    color: black; }
  .select2-container--default .select2-selection--multiple {
    font-size: 1.8rem;
    border-radius: 0;
    padding: .8rem .7rem .7rem .7rem;
    padding-right: 4rem;
    height: auto;
    line-height: 1.8rem; }
    .select2-container--default .select2-selection--multiple li {
      line-height: 1.5;
      font-size: 1.8rem; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      margin-top: .2rem; }
    .select2-container--default .select2-selection--multiple .select2-search--inline::before {
      content: ''; }
      .select2-container--default .select2-selection--multiple .select2-search--inline::before input {
        font-size: 1.8rem; }

/* Hintergrund generell für die Optionen */
.select2-dropdown {
  background-color: white;
  border-color: #a7a7a7;
  border-width: 1px;
  border-radius: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 600px) and (max-width: 799px) {
  /* label vor input fields setzen */
  .formLayout10 {
    /* immer um ein form element (label + div) */ }
    .formLayout10 .form-group {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .formLayout10 .form-group > label {
      width: 30%; }
    .formLayout10 .textareaForm {
      display: block; }
      .formLayout10 .textareaForm label,
      .formLayout10 .textareaForm .input:not(.checkbox) {
        width: 100%; }
    .formLayout10 .input:not(.checkbox) {
      width: 60%; }
    .formLayout10 .form-group.csc-form-element-fileupload {
      display: block; }
      .formLayout10 .form-group.csc-form-element-fileupload > label {
        margin-bottom: 4rem;
        padding-top: .8rem; }
        .formLayout10 .form-group.csc-form-element-fileupload > label::after {
          top: 0;
          width: auto;
          bottom: 0;
          left: 40%; }
        .formLayout10 .form-group.csc-form-element-fileupload > label::before {
          top: 1.8rem;
          left: 70%; } }

@media (min-width: 800px) {
  .formLayout10 form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .formLayout10 form > div {
      width: 100%; }
    .formLayout10 form .row {
      width: 48%; }
    .formLayout10 form .inputs-list {
      padding-top: 3.2rem; } }

@media (min-width: 1200px) {
  /* label vor input fields setzen */
  .formLayout10 {
    /* immer um ein form element (label + div) */ }
    .formLayout10 .form-group {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .formLayout10 .form-group > label {
      width: 32%;
      margin-bottom: 0; }
    .formLayout10 .textareaForm {
      display: block; }
      .formLayout10 .textareaForm label,
      .formLayout10 .textareaForm .input:not(.checkbox) {
        width: 100%; }
      .formLayout10 .textareaForm label {
        margin-bottom: 1rem; }
    .formLayout10 .input:not(.checkbox) {
      width: 67%; }
    .formLayout10 .inputs-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .formLayout10 .inputs-list .form-check {
        width: 48%; }
    .formLayout10 .form-group.csc-form-element-fileupload > label {
      margin-bottom: 4rem;
      padding-top: .8rem; }
      .formLayout10 .form-group.csc-form-element-fileupload > label::after {
        top: 0;
        width: auto;
        bottom: 0;
        left: 33%; }
      .formLayout10 .form-group.csc-form-element-fileupload > label::before {
        top: 1.8rem;
        left: 68%; } }

/************************************************
* TEXT
*************************************************/
.text {
  padding: 2rem 0; }
  .text .left p {
    margin-top: 0; }
  .text .right {
    margin-top: 3rem; }
    .text .right span {
      text-transform: uppercase; }
    .text .right span + p {
      margin-top: 0; }
    .text .right ul {
      margin: 0;
      padding: 0; }
      .text .right ul li {
        font-size: 1.8rem;
        display: inline-block; }
        .text .right ul li::after {
          content: '//';
          margin: 0 .5rem; }
        .text .right ul li:last-of-type::after {
          content: none; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 600px) {
  .text .right ul li {
    font-size: 1.7rem; } }

@media (min-width: 700px) {
  .text {
    padding: 0 0 5rem 0; } }

@media (min-width: 750px) {
  .text .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .text .wrap .left {
      width: 100%;
      padding: 0 5%; }
    .text .wrap .right {
      width: 100%;
      padding: 0 5%; } }

@media (min-width: 900px) {
  .text .wrap .left {
    width: 56%;
    padding: 0; }
  .text .wrap .right {
    width: 40%;
    padding: 0;
    margin: 0; } }

@media (min-width: 1024px) {
  .text {
    padding: 0 0 7rem 0; }
    .text .wrap .left {
      width: 58%; }
    .text .wrap .right {
      width: 35%; } }

@media (min-width: 1200px) {
  .text {
    padding: 0 0 10rem 0; }
    .text .wrap .left {
      padding-left: 5%; }
    .text .wrap .right {
      padding-right: 5%; } }

@media (min-width: 1400px) {
  .text .wrap .left {
    width: 50%; }
  .text .wrap .right {
    width: 40%; } }

@media (min-width: 1500px) {
  .text {
    padding: 0 0 13rem 0; } }

@media (min-width: 1600px) {
  .text .wrap .left {
    padding-left: 8%; }
  .text .wrap .right {
    padding-right: 8%; } }

/************************************************
* CONTENTIMAGE
*************************************************/
.mockUp .wrap {
  background-size: cover;
  background-position: center;
  padding-top: 52%; }

.screenshot {
  padding: 3rem 0; }
  .screenshot .wrap {
    display: flex;
    justify-content: center; }
  .screenshot.tablet .wrap,
  .screenshot.mobile .wrap,
  .screenshot.desktopMobile .wrap {
    flex-wrap: wrap; }
  .screenshot.desktop figure {
    margin-bottom: 0; }
  .screenshot figure {
    margin: 0;
    margin-bottom: 3rem;
    text-align: center; }
    .screenshot figure img {
      border: 1px solid #d7d7d7; }

.divider {
  background-size: cover;
  background-position: center;
  padding-top: 35%; }

/*screenshot mobile + text*/
.screenshot.mobile.text .text {
  padding: 3rem 0 4rem 0; }

.screenshot.mobile.text figure {
  margin-right: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .screenshot.mobile figure {
    width: 55%;
    margin: 0 20% 3rem 20%; }
  .screenshot.mobile.text figure {
    margin: 0; } }

@media (min-width: 600px) {
  .screenshot.mobile figure {
    margin: 0 5% 3rem 5%;
    width: 40%; }
  .screenshot.tablet figure {
    width: 80%; } }

@media (min-width: 700px) {
  .screenshot {
    padding: 5rem 0; }
    .screenshot.mobile figure {
      margin: 0 5% 3rem 5%;
      width: 40%; }
    .screenshot.tablet figure {
      width: 70%; }
    .screenshot.mobile.text .text {
      width: 60%;
      padding-top: 0;
      margin-right: 5%; }
    .screenshot.mobile.text figure {
      width: 35%;
      text-align: left; }
    .screenshot.mobile.text.screenshotLeft .text {
      margin-left: 5%;
      margin-right: 0; }
    .screenshot.mobile.text.screenshotLeft figure {
      order: -1;
      text-align: right; } }

@media (min-width: 800px) {
  .screenshot {
    padding: 5rem 0; }
    .screenshot.mobile figure,
    .screenshot.mobile figure:nth-of-type(2n) {
      width: 31%;
      margin: 0 3.5% 0 0; }
    .screenshot.mobile figure:nth-of-type(3n) {
      margin: 0; }
    .screenshot.tablet figure {
      width: 60%; } }

@media (min-width: 1024px) {
  .screenshot.desktop, .screenshot.mobile {
    padding: 7rem 10%; }
  .screenshot.desktopMobile {
    padding: 7rem 0; }
  .screenshot.tablet {
    padding: 7rem 5%; }
  .screenshot.mobile figure,
  .screenshot.mobile figure:nth-of-type(2n) {
    width: 31%;
    margin: 0 3.5% 0 0; }
  .screenshot.mobile figure:nth-of-type(3n) {
    margin: 0; }
  .screenshot.tablet figure {
    width: 49%;
    margin-right: 2%; }
    .screenshot.tablet figure:nth-of-type(2n) {
      margin-right: 0; }
  .screenshot.desktopMobile .desktop {
    width: 75%;
    margin-right: 5%; }
  .screenshot.desktopMobile .mobile {
    width: 20%; } }

@media (min-width: 1200px) {
  .screenshot.desktop, .screenshot.mobile {
    padding: 10rem 15%; }
  .screenshot.desktopMobile {
    padding: 10rem 5%; }
  .screenshot.tablet figure {
    width: 45%;
    margin-right: 10%; }
  .divider {
    padding-top: 30%; } }

@media (min-width: 1300px) {
  .screenshot.mobile figure,
  .screenshot.mobile figure:nth-of-type(2n) {
    width: 28%;
    margin-right: 8%; }
  .screenshot.mobile.text .text {
    width: 55%;
    margin-right: 10%; }
  .screenshot.mobile.text figure {
    width: 35%;
    text-align: left; } }

@media (min-width: 1500px) {
  .screenshot.desktop, .screenshot.mobile {
    padding: 13rem 15%; }
  .screenshot.desktop figure img {
    max-width: 1000px; }
  .screenshot.desktopMobile {
    padding: 13rem 10%; }
  .screenshot.tablet {
    padding: 13rem 10%; }
  .screenshot.mobile figure,
  .screenshot.mobile figure:nth-of-type(2n) {
    width: 25%;
    margin-right: 12.5%; } }

@media (min-width: 1600px) {
  .screenshot .wrap {
    padding: 0 6%; }
  .screenshot.desktopMobile .desktop {
    text-align: left; }
  .screenshot.desktopMobile .mobile {
    text-align: right; }
  .mockUp .wrap {
    padding-top: 0;
    height: 750px; } }

@media (min-width: 1800px) {
  .screenshot.mobile.text .wrap {
    justify-content: center; }
    .screenshot.mobile.text .wrap .text {
      width: 45%;
      padding-top: 0; }
    .screenshot.mobile.text .wrap figure {
      width: 35%; } }

/************************************************
* TEXTSTANDARD
*************************************************/
.textStandard {
  padding: 2rem 0; }
  .textStandard span {
    font-weight: 700; }
  .textStandard ul {
    padding: 0;
    margin: 0; }
    .textStandard ul li {
      list-style-type: none;
      font-size: 1.8rem;
      line-height: 1.5;
      margin-bottom: 1rem;
      padding-left: 1.5rem;
      position: relative; }
      .textStandard ul li::before {
        content: '\e803';
        position: absolute;
        left: -.5rem; }
  .textStandard h3 {
    margin: 2.5rem 0 0 0; }
  .textStandard a {
    color: #85c800;
    text-decoration: none;
    font-size: 1.8rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 600px) {
  .textStandard ul li,
  .textStandard a {
    font-size: 1.7rem; } }

@media (min-width: 110em) {
  .textStandard {
    max-width: 100em;
    margin: 0 auto; } }

@media (min-width: 1024px) {
  .textStandard a:hover {
    border-bottom: 1px solid #85c800; } }

/************************************************
* QUOTE
*************************************************/
.quote {
  padding: 8rem 0 3rem 0; }
  .quote .content {
    font-family: 'Playfair Display', serif;
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    position: relative; }
    .quote .content::before {
      font-family: 'Playfair Display', serif;
      content: '”';
      font-size: 16rem;
      font-weight: 400;
      position: absolute;
      top: -12.5rem;
      left: 50%;
      transform: translateX(-50%); }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 700px) {
  .quote {
    padding: 11rem 0 5rem 0; }
    .quote .wrap {
      display: flex;
      justify-content: center; }
    .quote .content {
      width: 90%; }
      .quote .content::before {
        font-size: 19rem;
        top: -15.5rem; } }

@media (min-width: 1024px) {
  .quote {
    padding: 13rem 0 7rem 0; }
    .quote .content {
      width: 80%;
      font-size: 2.4rem; }
      .quote .content::before {
        font-size: 22rem;
        top: -18rem; } }

@media (min-width: 1200px) {
  .quote {
    padding: 14rem 0 10rem 0; }
    .quote .content {
      width: 70%; } }

@media (min-width: 1250px) {
  .quote .content {
    font-size: 2.7rem; } }

@media (min-width: 1500px) {
  .quote {
    padding: 16rem 0 13rem 0; }
    .quote .content {
      font-size: 3rem; } }

/************************************************
* Cookie Banner
*************************************************/
#privacyWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9); }

#privacyInner {
  width: 94%;
  margin-left: auto;
  margin-right: auto; }

#closePrivacy {
  background-color: #ccc;
  display: inline-block;
  padding: .5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  color: black;
  text-decoration: none;
  transition: background-color .5s ease; }
  @media (min-width: 1025px) {
    #closePrivacy:hover {
      background-color: #85c800;
      color: white; } }

/************************************************
* FOOTER
*************************************************/
/************************************************
* General FOOTER Stylings
*************************************************/
footer {
  background-color: #242424;
  color: white;
  font-size: 1.7rem;
  padding: 2rem 0 1rem 0;
  display: flex;
  width: 100%; }

footer i {
  color: white;
  margin-right: 1rem;
  font-size: 2rem;
  padding: 1rem;
  transition: color .2s ease-in; }
  footer i:first-of-type {
    padding-left: 0; }

footer a {
  color: white;
  text-decoration: none; }

footer .social span {
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  margin-top: 2rem; }
  footer .social span a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    transition: color .2s ease-in; }

footer address {
  font-style: normal;
  margin-top: 2rem; }

footer address a {
  display: block;
  transition: color .2s ease-in; }

footer address a.tel {
  margin-top: 2rem; }

.footerNav ul {
  padding: 0; }
  .footerNav ul li {
    list-style-type: none; }
    .footerNav ul li:first-of-type a {
      margin-left: 0; }
  .footerNav ul a {
    display: block;
    padding: 1rem 1rem 0 0;
    text-transform: uppercase;
    transition: color .2s ease-in; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 450px) {
  footer .social span {
    display: inline-block;
    margin-top: 0; } }

@media (min-width: 700px) {
  footer .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto; }
  .social {
    width: 100%; }
  footer address {
    width: 100%; }
  .footerNav ul {
    width: 100%; }
    .footerNav ul li {
      display: inline-block; }
    .footerNav ul a {
      padding: 1rem 2.5rem 1rem 0; } }

@media (min-width: 900px) {
  footer {
    font-size: 1.5rem; }
  footer address div,
  footer address a  {
    display: inline-block; }
  footer address .org,
  footer address .street,
  footer address .postcode,
  footer address .locality {
    margin-right: 1.5rem; }
  footer address .tel,
  footer address .email {
    margin: 0 1.5rem 0 0;
    position: relative;
    padding-left: 3rem; }
    footer address .tel::before,
    footer address .email::before {
      content: '//';
      position: absolute;
      left: 0; }
  footer address .email {
    margin-right: 0; } }

@media (min-width: 915px) {
  footer address a.tel,
  footer address a.email {
    margin-top: 0; } }

@media (min-width: 1025px) {
  footer address a.tel:hover,
  footer address a.email:hover {
    color: #bababa; }
    footer address a.tel:hover::before,
    footer address a.email:hover::before {
      color: white; }
  .footerNav a:hover {
    color: #bababa; }
  .social a:hover i {
    color: #bababa; }
  .social span a:hover {
    color: #bababa; } }

@media (min-width: 1300px) {
  footer address {
    width: 75%; }
  .footerNav {
    width: 24%; }
    .footerNav ul {
      display: flex;
      justify-content: space-between;
      float: right;
      margin-top: 1rem; }
      .footerNav ul a {
        padding: 1rem 0 1rem 2.5rem; } }

@media (min-width: 1500px) {
  .footerNav {
    width: 21%; } }

/************************************************
* fade in body
*************************************************/
body {
  opacity: 1; }

/************************************************
* General CONTENT Stylings
*************************************************/

	button,
	.button {
		display: inline-block;
		font-family: 'PT Sans', sans-serif;
		font-size: 1.7rem;
    	line-height: 1.5;
    	background-color: $brandSecondary;
		color: white;
		border: 1px solid $brandSecondary;
    	border-radius: 3px;

    	padding: 1.3rem 2.7rem;
    	margin: 4rem 3rem 0 0;
    	text-decoration: none;
    	transition: all .3s ease-in;
	}

	.back {
		padding: 0 0 6rem 0;
		text-align: center;

		a {
			font-family: 'Playfair Display', serif;
			font-size: 1.9rem;
			text-decoration: none;
			color: $brandSecondary;
			transition: all .3s ease-in;
			
			&::after {
				@extend %icons;
				content: '\f178';
				color: $brandPrimary;
				margin-left: .7rem;
				transition: all .3s ease-in;
			}	
		}
	}

	.cta-button {
		padding: 0 0 6rem 0;
		text-align: center;

		a {
			font-family: 'PT Sans', sans-serif;
			font-size: 1.9rem;
			text-decoration: none;
			color: white;
			background-color: $brandPrimary;
			font-weight: bold;
			padding: 0.5em 1em;
			display: inline-block;
		}
	}

	body > div {
		opacity: 1;
  	transition: opacity .25s ease-in;
	}

	p,
	.form-check-label { 
		a {
			color: darken($brandPrimary, 10%);
			text-decoration: none;
			transition: color 200ms linear;
			&:hover {
				border-bottom: 1px solid $brandPrimary;
			}
	  }
	}

	.attentionBox {
		position: fixed;
		text-align:center;
		width: 100%;
		padding: 0 5%;
		z-index: 100;
        bottom: 10%;

		> div {
			border-radius: 30px;
			background: rgba(0,0,0,.8);
			color: #fff;
			font-weight: bold;
			margin: 0 auto;
			max-width: 1280px;
			padding: 1em 2em;
		}
	}
/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 700px) {
		.back {
			padding: 0 0 8rem 0;
		}
	}

	@media (min-width: 1025px) {
		button:hover,
		.button:hover {
			border: 1px solid $brandPrimary;
			color: $brandSecondary;
			background-color: white;
		}

		.back a {
			font-size: 2.1rem;

			&:hover::after {
				transform: translateX(30%);
			}
		}
	}

	@media (min-width: 1200px) {
		.back {
			padding: 0 0 10rem 0;
		}
	}

	@media (min-width: 1250px) {
		.back a {
			font-size: 2.5rem;

			&::after {
				margin-left: 1.2rem;
			}
		}
	}

	@media (min-width: 1500px) {
		.back {
			padding: 0 0 13rem 0;
		}
	}

/* responsive new button elemen */

@media (min-width: 700px) {
	.cta-button {
		padding: 0 0 8rem 0;
	}
}

@media (min-width: 1025px) {
	.cta-button a:hover {
		background-color: black;
	}

	.cta-button a {
		font-size: 2.1rem;
	}
}

@media (min-width: 1200px) {
	.cta-button {
		padding: 0 0 10rem 0;
	}
}

@media (min-width: 1250px) {
	.cta-button a {
		font-size: 2.5rem;
	}
}

@media (min-width: 1500px) {
	.cta-button {
		padding: 0 0 13rem 0;
	}
}
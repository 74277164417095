/* clearfix */

@mixin clearfix{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}

/* Breakpoints */

@mixin bp($point) {

    $bp-phonep: "(max-width: 320px)";
    $bp-phonel: "(max-width: 480px)";
    $bp-tabletp: "(max-width: 768px)";
    $bp-tabletl: "(max-width: 1023px)";
    $bp-desktop: "(max-width: 1200px)";

    @if (type-of($point) == string) {

        @if $point == desktop {
            @media #{$bp-desktop} { @content; }
        }@else if $point == tabletl {
            @media #{$bp-tabletl} { @content; }
        }@else if $point == tabletp {
            @media #{$bp-tabletp}  { @content; }
        }@else if $point == phonel {
            @media #{$bp-phonel} { @content; }
        }@else if $point == phonep {
            @media #{$bp-phonep}  { @content; }
        }

    } @else if (unit($point) == px) {
        @media (max-width: $point)  { @content; }
    }

}

// Baseline, measured in pixels
// The value should be the same as the font-size value for the html element
// If the html element's font-size is set to 62.5% (of the browser's default font-size of 16px),
// then the variable below would be 10px.
$baseline-px: 10px;
@mixin rem($property, $px-values) {

  // Convert the baseline into rems
  $baseline-rem: $baseline-px / 1rem * 1;
  // Print the first line in pixel values
  #{$property}: $px-values;
  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px-values) == "number" {

    #{$property}: $px-values / $baseline-rem;

  } @else {

    // Create an empty list that we can dump values into
    $rem-values: ();

    @each $value in $px-values {
      // If the value is zero or not a number, return it
      @if $value == 0 or type-of( $value ) != "number" {
        $rem-values: append($rem-values, $value);
      } @else {
        $rem-values: append($rem-values, $value / $baseline-rem);
      }
    }

    // Return the property and its list of converted values
    #{$property}: $rem-values;

  }

}

/* fluid type */

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin fluid-type-step($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

/* grid stuff */

@function gutter($number, $context: 100%){
    $size: ($gridgutter * $number);
    @return ($context / 100) * $size;
}

@function col($number, $context: 100%){
    $size: ($gridcol * $number) + ($gridgutter * ($number - 1));
    @return ($context / 100) * $size;
}

@function col-gutter($number, $context: 100%){
    $size: ($gridcol * $number) + ($gridgutter * $number);
    @return ($context / 100) * $size;
}

@function grid() {
    @return linear-gradient(90deg,
        $gridcolor col-gutter(0),   $gridcolor col(1),
        transparent col(1),        transparent col-gutter(1),
        $gridcolor  col-gutter(1),  $gridcolor col(2),
        transparent col(2),        transparent col-gutter(2),
        $gridcolor  col-gutter(2),  $gridcolor col(3),
        transparent col(3),        transparent col-gutter(3),
        $gridcolor  col-gutter(3),  $gridcolor col(4),
        transparent col(4),        transparent col-gutter(4),
        $gridcolor  col-gutter(4),  $gridcolor col(5),
        transparent col(5),        transparent col-gutter(5),
        $gridcolor  col-gutter(5),  $gridcolor col(6),
        transparent col(6),        transparent col-gutter(6),
        $gridcolor  col-gutter(6),  $gridcolor col(7),
        transparent col(7),        transparent col-gutter(7),
        $gridcolor  col-gutter(7),  $gridcolor col(8),
        transparent col(8),        transparent col-gutter(8),
        $gridcolor  col-gutter(8),  $gridcolor col(9),
        transparent col(9),        transparent col-gutter(9),
        $gridcolor  col-gutter(9),  $gridcolor col(10),
        transparent col(10),       transparent col-gutter(10),
        $gridcolor  col-gutter(10), $gridcolor col(11),
        transparent col(11),       transparent col-gutter(11),
        $gridcolor  col-gutter(11), $gridcolor col(12),
        transparent col(12)
    );
}

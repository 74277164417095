/* hide elements first */
.hide {
  opacity: 0;
}

/* Fade In Up Effect */

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 2em, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInUp {
    animation-name: fadeInUp;
  }

  .fadeInUp-active {
    animation: fadeInUp 1s ease forwards;
  }

/* fadeIn */

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
  }

  .fadeIn-active {
    animation: fadeIn 2s .8s forwards;
  }

/* fadeInDown */

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInDown {
    animation-name: fadeInDown;
  }

  .fadeInDown-active {
    animation: fadeInDown .7s 1.5s ease forwards;
  }

/* fadeInRight */

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(20%, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInRight {
    animation-name: fadeInRight;
  }

  .fadeInRight-active {
    animation: fadeInRight 1s forwards;
  }


/* fadeInRightNav */

  @keyframes fadeInRightNav {
    from {
      opacity: 0;
      transform: translate3d(10%, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInRightNav {
    animation-name: fadeInRightNav;
  }

/* fadeInLeft */

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-20%, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }

  .fadeInLeft-active {
    animation: fadeInLeft 1s forwards;
  }

/* bounce */

  @keyframes bounce {
    from, 20%, 53%, 80%, to {
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transform: translate3d(0,0,0);
    }

    40%, 43% {
      animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      transform: translate3d(0,-4px,0);
    }
  }

  .bounce {
    animation-name: bounce;
    transform-origin: center bottom;
  }
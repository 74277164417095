/************************************************
* TEAM
*************************************************/

	@keyframes fadeInLeft {
	  from {
	    opacity: 0;
	    transform: translate3d(-20%, 0, 0);
	  }

	  to {
	    opacity: 1;
	    transform: none;
	  }
	}

	@keyframes fadeInRight {
	  from {
	    opacity: 0;
	    transform: translate3d(20%, 0, 0);
	  }

	  to {
	    opacity: 1;
	    transform: none;
	  }
	}

	.entry {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-bottom: 4rem;

		.image {
			width: 90%;
			margin-bottom: 2rem;
			align-self: center;

			&.fadeInLeft-active {
				animation: fadeInLeft 1s forwards;
			}
		}

		.text {
			width: 90%;
			margin: 0;
			padding: 0;
			align-self: center;

			&.fadeInRight-active {
				animation: fadeInRight 1s forwards;
			}
		}
	}

	.entry:nth-child(2n){
		.image {
			&.fadeInLeft-active {
				animation: fadeInRight 1s forwards;
			}
		}

		.text {
			&.fadeInRight-active {
				animation: fadeInLeft 1s forwards;
			}
		}
	}

	.image img {
		width: 100%;
		height: auto;
		display: block;
	}

	.text {
		span {
			font-size: 1.6rem;
			line-height: 1.5;
			text-transform: uppercase;
			margin: 0;
		}
		
		h3 {
			margin: .5rem 0 0 0;
		}

		p {
			margin-bottom: 0;
		}
	}


/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 400px) {
		.entry .text,
		.entry .image {
			width: 80%;
		}
	}

	@media (min-width: 500px) {
		.entry .text,
		.entry .image {
			width: 60%;
		}
	}

	@media (min-width: 650px) {
		.entry {
			flex-direction: row;
			justify-content: center;
			margin-bottom: 4rem;
			
			.text {
				margin: 0 0 0 1.5rem;
				width: 47%;
			}

			.image {
				margin: 0 1.5rem 0 0;
				width: 47%;
			}
		}

		.entry:nth-of-type(2n) {
			.text {
				order: -1;
				text-align: right;
				margin: 0 1.5rem 0 0;
			}

			.image {
				margin: 0 0 0 1.5rem;
			}
		}
	}

	@media(min-width: 800px) {
		.entry {
			margin-bottom: 5rem;

			.text,
			.image {
				width: 40%
			}
		}
	}

	@media (min-width: 950px) {
		.entry {
			.text,
			.image {
				width: 32%;
			}
		}
	}

	@media (min-width: 1100px) {
		.entry .text,
		.entry .image {
			width: 28%;
		}
	}

	@media (min-width: 1300px) {
		.entry {			
			.text,
			.image {
				width: 25%;
			}
		}
	}

/************************************************
* formLayout10
*************************************************/

    .formLayout10 {
        padding: 3rem 0;

        h1,
        h2 {
            margin-top: 0;
        }

        /* static text */
        h2 + div {
            margin-bottom: 4rem;
        }


        /* immer um ein form element (label + div) */
        .form-group {
            margin-bottom: 3rem;
        }


        /* Grid Row */

        .row {
            width: 100%;
            margin-bottom: 2rem;

            + .row h2 {
                margin-top: 3rem;
            }

        }

        /* remove prop. stylings */

        input[type="text"],
        input[type="submit"],
        input[type="file"],
        textarea {
            -webkit-appearance: none;
        }

        /* Label allgemein */

        label {
            width: 100%;
            font-size: 1.8rem;
            display: block;
            margin-bottom: 1rem;
        }

        /* Fieldset */

        fieldset {
            margin: 0 0 2rem 0;
            padding: 0;
            border: 0;

            legend {
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 2rem;
                padding: 0;
            }

            > .form-group,
            .clearfix {
                margin-bottom: 1rem;
            }
        }


        /* Input & Textarea */

        .input:not(.checkbox) {
            width: 100%;

            input,
            select,
            textarea {
                font-family: 'PT Sans', sans-serif;
                border: 1px solid $brandSecondary;
                border-radius: 3px;
                font-size: 1.5rem;
                padding: .8rem;
                width: 100%;
                &:focus {
                    border: 1px solid $brandPrimary;
                }
            }

            textarea {
                height: 20rem;
            }
        }

        /* checkboxen */

        label.form-check-label span {
            display: inline-block;
            margin-left: .8rem;
        }

        .input.checkbox {
            width: 100%;

            input {

            }
        }

        .csc-form-element-fileupload {
            position: relative;
            cursor: pointer;

            label {
                width: 100%;
                margin-bottom: 9rem;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    border: 1px solid $brandSecondary;
                    border-radius: 3px;
                    width: 100%;
                    right: 0;
                    top: 3.3rem;
                    bottom: 0;
                    height: 3.8rem;
                    cursor: pointer;
                }

                &::before {
                    content: 'Datei auswählen';
                    position: absolute;
                    top: 5.1rem;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                }
            }

            input[type="file"] {
                display: none;
            }
        }

        /* Submit */
        
        .actions {
            width: 100%;
            text-align: right;

            .btn-group {
                text-align: left;
            }

            span{
                display: inline-block;
            }

            button {
                background: transparent none;
                color: black; 
                cursor: pointer;
                margin: 0;
            }
        }
    }


/************************************************
 * Select 2 Stylings überschreiben
 ************************************************/

    .select2-container--default{
        font-size: 1.5rem;
        width: 100%;
        font-family: 'PT Sans', sans-serif;

        .select2-selection--single{
            font-size: 1.5rem;
            padding: .4rem .4rem .3rem .4rem;
            padding-right: 4rem;
            font-family: 'PT Sans', sans-serif;
            background-color: white;
            border-radius: 3px;
            border: 1px solid $brandSecondary;

            height: auto;

            .select2-selection__arrow {
                height: auto;
                bottom: 0;
                width: 4rem;
                top: 0;
                right: 0;
                border: 1px solid $brandSecondary;
                background-color: $brandSecondary;
                border-left: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;

                b {
                    border: none;

                    &::after{
                        @extend %icons;
                        content: '\e801';
                        position: absolute;
                        top: -.8rem;
                        left: -.8rem;
                        font-size: 2.5rem;
                        color: white;
                    }
                }
            }

            .select2-selection__rendered {
                //color: $fontColor;
                font-size: 1.8rem;
                padding: 0;
                li:before{
                    content: '';
                }
            }
        }


        // Container im offenen Zustand
        &.select2-container--open{
            .select2-selection--single .select2-selection__arrow b {
                border: none;
                &::after{
                    content: '\e804';
                }
            }
        }

        .select2-results__option {
            padding: 1rem;
            //color: $fontColor;
        }


        /* Hintergrund für aktive Auswahl*/
        .select2-results__option[aria-selected="true"]{
            background-color: $brandSecondary !important;
            color: white !important;
        }


        /* Hintergrund für gehoverte Auswahl*/
        .select2-results__option--highlighted[aria-selected]{
            background-color: white;
            color: black;
        }

        .select2-selection--multiple{
            font-size: 1.8rem;
            border-radius: 0;
            padding: .8rem .7rem .7rem .7rem;
            padding-right: 4rem;
            height: auto;
            line-height: 1.8rem;

            li {
                line-height: 1.5;
                font-size: 1.8rem;
            }

            .select2-selection__choice {
                margin-top: .2rem;
            }

            .select2-search--inline::before{
                content: '';
                input {
                    font-size: 1.8rem;
                }
            }
        }
    }


    /* Hintergrund generell für die Optionen */
    .select2-dropdown{
        background-color: white;
        border-color: #a7a7a7;
        border-width: 1px;
        border-radius: 0;
    }




/************************************************
* RESPONSIVE
*************************************************/

@media (min-width: 600px) and (max-width: 799px) {

    /* label vor input fields setzen */

    .formLayout10 {

        /* immer um ein form element (label + div) */
        .form-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .form-group > label{
            width: 30%;
        }

        .textareaForm {
            display: block;
            label,
            .input:not(.checkbox){
                width: 100%;
            }
        }

        .input:not(.checkbox) {
            width: 60%;
        }

        .form-group.csc-form-element-fileupload{
            display: block;
            > label {
                margin-bottom: 4rem;
                padding-top: .8rem;

                &::after {
                    top: 0;
                    width: auto;
                    bottom: 0;
                    left: 40%;
                }

                &::before {
                    top: 1.8rem;
                    left: 70%;
                }
            }
        }

    }

}

@media (min-width: 800px) {

    .formLayout10 form {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
            width: 100%;
        }

        .row {
            width: 48%;
        }

        .inputs-list {
            padding-top: 3.2rem;
        }
    }
}

@media (min-width: 1200px){

    /* label vor input fields setzen */

    .formLayout10 {

        /* immer um ein form element (label + div) */
        .form-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .form-group > label{
            width: 32%;
            margin-bottom: 0;
        }

        .textareaForm {
            display: block;

            label,
            .input:not(.checkbox){
                width: 100%;
            }

            label {
                margin-bottom: 1rem;
            }
        }

        .input:not(.checkbox) {
            width: 67%;
        }

        .inputs-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .form-check {
                width: 48%;
            }
        }

        .form-group.csc-form-element-fileupload{
            > label {
                margin-bottom: 4rem;
                padding-top: .8rem;

                &::after {
                    top: 0;
                    width: auto;
                    bottom: 0;
                    left: 33%;
                }

                &::before {
                    top: 1.8rem;
                    left: 68%;
                }
            }
        }
    }
}
/************************************************
* General FOOTER Stylings
*************************************************/

	footer {
		background-color: $brandSecondary;
		color: white;
		font-size: 1.7rem;
		padding: 2rem 0 1rem 0;
		display: flex;
		width: 100%;
	}

	footer i {
		color: white;
		margin-right: 1rem;
		font-size: 2rem;
		padding: 1rem; 
		transition: color .2s ease-in;
		&:first-of-type {
			padding-left: 0;
		}
	}

	footer a {
		color: white;
		text-decoration: none;
	}

	footer .social span {
		font-weight: 400;
		text-transform: uppercase;
		display: block;
		margin-top: 2rem;
		a {
			border-bottom: 1px solid rgba(255, 255, 255, .6);
			transition: color .2s ease-in;
		}
	}

	footer address {
		font-style: normal;
		margin-top: 2rem;
	}

	footer address a {
		display: block;
		transition: color .2s ease-in;
	}

	footer address a.tel {
		margin-top: 2rem;
	}

	.footerNav ul {
		padding: 0;

		li {
			list-style-type: none;
			&:first-of-type a {
				margin-left: 0;
			}
		}

		a {
			display: block;
			padding: 1rem 1rem 0 0;
			text-transform: uppercase;
			transition: color .2s ease-in;
		}
	}


/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 450px) {

		footer .social span {
			display: inline-block;
			margin-top: 0;
		}
	}

	@media (min-width: 700px) {
		
		footer .wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 auto;
		}

		.social {
			width: 100%;
		}

		footer address {
			width: 100%;
		}

		.footerNav ul {
			width: 100%;

			li {
				display: inline-block;
			}

			a {
				padding: 1rem 2.5rem 1rem 0;
			}
		}
	}

	@media (min-width: 900px) {
		
		footer {
			font-size: 1.5rem;
		}

		footer address {

			div,
			a {
				display: inline-block;					
			}

			.org,
			.street,
			.postcode,
			.locality {
				margin-right: 1.5rem;
			}

			.tel,
			.email {
				margin: 0 1.5rem 0 0;
				position: relative;
				padding-left: 3rem;
				
				&::before {
					content: '//';
					position: absolute;
					left: 0;
				}
			}

			.email{
				margin-right: 0;
			}
		}

	}

	@media (min-width: 915px) {
		
		footer address {
			a.tel,
			a.email {
				margin-top: 0; 
			}
		}
	}

	@media (min-width: 1025px) {
		
			footer address a.tel:hover,
			footer address a.email:hover {
				color: #bababa;
				&::before {
					color: white;
				}
			}

		.footerNav a:hover {
			color: #bababa;
		}

		.social {

			a:hover i {
				color: #bababa;
			}

			span a:hover {
				color: #bababa;
			}

		}
	}

	@media (min-width: 1300px) {

		footer address {
			width: 75%;
		}

		.footerNav {
			width: 24%;
			
			ul {
				display: flex;
				justify-content: space-between;
				float: right;
				margin-top: 1rem;

				a {
					padding: 1rem 0 1rem 2.5rem;
				}
			}
		}
	}

	@media (min-width: 1500px) {
		.footerNav {
			width: 21%;
		}
	}


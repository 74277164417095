/************************************************
* SETUP
*************************************************/

	@import "_base/_mixins.scss";
    @import "_base/_settings.scss";
	@import "_base/_colors.scss";
	@import "_base/_icons.scss";

/************************************************
* Base Definitions
*************************************************/

    @import "_base/_typo.scss";
    @import "_base/_icons.scss";
    @import "_base/_default.scss";
    @import "_base/_animations.scss";

/************************************************
* Library's (optional)
*************************************************/

    @import "_libs/_flexNav.slide.scss";
    @import "_libs/_select2.scss";

/************************************************
* CONTENT
*************************************************/

    @import "_content/_general.scss";
    @import "_content/_intro.scss";

/************************************************
* CONTENT
*************************************************/

	@import "_content/_featureBoxes.scss";
	@import "_content/_callToAction.scss";
	@import "_content/_scrollTop.scss";
	@import "_content/_iconText.scss";
	@import "_content/_team.scss";
	@import "_content/_imageText.scss";
	@import "_content/_address.scss";
	//@import "_content/_forms.scss";
	@import "_content/_formLayout10.scss";
	@import "_content/_text.scss";
	@import "_content/_contentImage.scss";
	@import "_content/_textStandard.scss";
	@import "_content/_quote.scss";
	@import "_content/_privacy.scss";

/************************************************
* FOOTER
*************************************************/

	@import "_footer/_general.scss";

/************************************************
* fade in body
*************************************************/

body {
    opacity: 1;
}

/************************************************
* Cookie Banner
*************************************************/

    #privacyWrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background-color: rgba(white, 0.9);
    }

    #privacyInner{
        width: 94%;
        margin-left: auto;
        margin-right: auto;
    }

    #closePrivacy {
        background-color: #ccc;
        display: inline-block;
        padding: .5rem 1rem;
        margin-left: 1rem;
        cursor: pointer;
        color: black;
        text-decoration: none;
        transition: background-color .5s ease;

        @media (min-width: 1025px) {
            &:hover {
                background-color: $brandPrimary;
                color: white;
            }
        }
    }


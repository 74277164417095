/************************************************
* natural box-sizing
*************************************************/

*, *:after, *:before{
	box-sizing: border-box;
}

/************************************************
* responsive images
*************************************************/

img {
	// max-width: 100%;
	// height: auto;
}


/************************************************
* general CSS Stylings
*************************************************/

::-moz-selection { background-color: $brandPrimary; }
::selection { background-color: $brandPrimary; }

.wrap {
	width: 94%;
	margin-left: auto;
	margin-right: auto;
}

.noBottomPadding {
	padding-bottom: 0 !important;
}

.noBorder img {
	border: none !important;
}

@media (min-width: 1600px) {
	.wrap{
		max-width: 1500px;
	}
}

/************************************************
* IMAGETEXT
*************************************************/

  .address .content {
    margin-bottom: 2rem;

    .addressText {
      margin-bottom: 2rem;
    }

    .image {
      margin-bottom: 2rem;

      img {
        border: 1px solid $brandSecondary;
      }
    }

    .addressText address {
      font-size: 1.7rem;
      line-height: 1.5;
      font-style: normal;

      .org {
        font-weight: 700;
      }

      .postcode,
      .locality {
        display: inline-block;
      }

      .tel,
      .email,
      .url {
        font-size: 1.7rem;
        text-decoration: none;
        color: $brandSecondary;
        font-weight: 400;
      }

      span {
        font-size: 1.7rem;
        line-height: 1.5;
        text-transform: none;
        text-decoration: none;
        color: $brandSecondary;
        &.postcode {
          margin-right: 8px;
        }
      }

      a.button {
        background-color: white;
        color: black;
      }
    }
  }

/************************************************
* RESPONSIVE
*************************************************/

  @media (min-width: 700px) {
    .address {
      margin: 0 0 5rem 0;

      .content .image {
        width: 75%;
      }
    }
  }

  @media (min-width: 900px) {
    .address .content {
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
      
      .addressText {
        margin: 0 0 0 1.5rem;
        width: 48%;
      }

      .image {
        margin: 0 1.5rem 0 0;
        width: 48%;
      }
    }
  }

  @media (min-width: 1024px) {
    .address {
      margin: 0 0 7rem 0;
    }
  }

  @media (min-width: 1200px) {
    .address {
      margin: 0 0 10rem 0;
      .content a.button {
        margin: 4rem 3rem 0 0;
      }
    }
  }

  @media (min-width: 1400px) {
    .address .content {
      .image,
      .addressText {
        width: 40%;
      }

      p {
        margin-top: 3rem;
      }
    }

  }

  @media (min-width: 1500px) {
    .address {
      margin: 0 0 13rem 0;
    }
  }

  @media (min-width: 1600px) {
    .address .content a.button {
      margin-top: 5rem;
    }
  }
/************************************************
* ICONTEXT
*************************************************/

	.iconText {
		padding: 0 0 3rem 0;

		h2 {
			margin: 0 auto;
			text-align: center;
			margin-bottom: 3rem;
			width: 95%;
		}

		span {
			font-family: 'Playfair Display', serif;
	    	font-size: 1.9rem;
	    	line-height: 1.5;
	    	display: block;
	   		margin-bottom: 4rem;
	    	text-align: center;
		}

		.content {
			text-align: center;
			margin-bottom: 4.5rem;

			h3 {
				color: $brandPrimary;
				margin: 1rem 0;
			}

			a {
				text-decoration: none;
				color: $brandPrimary;

				&:visited {
					text-decoration: none;
				}
			}
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				font-size: 1.7rem;
		    	line-height: 1.5;
		    	list-style-type: none;
			}
		}


		&.fourColumns {
			.wrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}

			.content {
				width: 60%;
				margin-bottom: 4rem;
			}
		}		
	}



/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 500px) {
		.iconText.fourColumns {
			.wrap {
				justify-content: flex-start;
			}

			.content {
				width: 45%;
				margin-right: 10%;
				padding: 0 2%; 

				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
		}		
	}
	
	@media (min-width: 600px) {
		.iconText.fourColumns .content {
			padding: 0 5%;
			margin-bottom: 5rem;
		}
	}

	@media (min-width: 700px) {
		.iconText {
			padding: 0 0 5rem 0;

			.wrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
			}

			.content {
				width: 47%;
				margin: 0 3% 6rem 0;

				&:nth-of-type(2n) {
					margin: 0 0 6rem 0;
				}
			}

			&.fourColumns .content,
			&.fourColumns .content:nth-of-type(2n) {
				width: 30%;
				margin-right: 5%;
				padding: 0 2%;
			}

			&.fourColumns .content:nth-of-type(3n) {
				margin-right: 0;
			}
		}
	}

	@media (min-width: 1024px) {
		.iconText {
			padding: 0 0 7rem 0;

			h2 {
				margin-bottom: 5rem;
			}

			.wrap span {
				font-size: 2.1rem;
				margin: 0 auto;
			}

			.content {
				width: 32%;
				margin: 0 2% 8rem 0;

				&:nth-of-type(2n) {
					margin: 0 2% 8rem 0;
				}

				&:nth-of-type(3n) {
					margin: 0 0 8rem 0;
				}
			}

			&.fourColumns {
				.content,
				.content:nth-of-type(2n),
				.content:nth-of-type(3n) {
					width: 20%;
					margin: 0 6.666667% 8rem 0;
					padding: 0;
				}

				.content:nth-of-type(4n) {
					margin: 0 0 8rem 0;
				}
			}
		}
	}

	@media (min-width: 1200px) {
		.iconText {
			padding: 0 0 10rem 0;

			.content {
				padding: 0 3%;
			}

			&.fourColumns {
				padding: 0 2%;
			}
		}
	}

	@media (min-width: 1250px) {
		.iconText .wrap span {
			font-size: 2.5rem;
		}
	}

	@media (min-width: 1300px) {
		.iconText.fourColumns {
			.content,
			.content:nth-of-type(2n),
			.content:nth-of-type(3n) {
				width: 18%;
				margin-right: 9.33333%;
				padding: 0;
			}
			
			.content:nth-of-type(4n) {
				margin-right: 0;
			}
		}
	}

	@media (min-width: 1500px) {
		.iconText {
			padding: 0 0 13rem 0;
		}

		.iconText.fourColumns {
			.content,
			.content:nth-of-type(2n),
			.content:nth-of-type(3n) {
				width: 16%;
				margin: 0 12% 10rem 0;
				padding: 0;
			}
			
			.content:nth-of-type(4n) {
				margin-right: 0;
			}
		}
	}

@media (min-width: 1024px) {
	.iconText a:hover {
		border-bottom: 1px solid $brandPrimary;
	}
}

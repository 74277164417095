/************************************************
* SCROLLTOP
*************************************************/

  .scrollTop {
    background-color: white;
    border: 2px solid $brandSecondary;
    display: none;
    width: 4rem;
    height: 4rem;
    position: fixed;
    right: 2%;
    bottom: 9%;
    border-radius: .3rem;
    opacity: 0.5;
    transition: opacity 0.5s ease;
    cursor: pointer;
    
    &:after {
      @extend %icons;
      position: absolute;
      content: '\e804';
      font-size: 3rem;
      color: $brandSecondary;
      left: .3rem;
      bottom: .1rem;
    }
    
    &:hover {
      opacity: 1;
    } 
  }



/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 1300px) {
		.scrollTop {
      right: 3%;
			bottom: 15%;
		}
	}
/************************************************
* IMAGETEXT
*************************************************/

.imageText .content {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 2rem;

	h2 {
		width: 100%;
		text-align: center;
		margin-bottom: 3rem;
	}

	.text,
	.image {
		margin-bottom: 2rem;
		width: 100%;
	}

	.text {
		&.fadeInRight-active {
			animation: fadeInRight 1s forwards;
		}

		p:first-of-type {
			margin-top: 0;
		}

		a {
		color: $brandSecondary;
		font-weight: 700;
		text-decoration: none;
		transition: color .2s ease-in;
		
		&:hover {
			color: $brandPrimary;
		}

		}

	}

	.image {
		&.fadeInLeft-active {
			animation: fadeInLeft 1s forwards;
		}

		img {
			display: block;
			height: auto;
			border: 1px solid $brandSecondary;
		}
	}
}


/************************************************
* RESPONSIVE
*************************************************/

@media (min-width: 700px) {
	.imageText.noBottomPadding .content .text {
		padding: 0 0 0 0;
	}

	.imageText.noMargin {
		margin: 0 0 0 0;

		.content {
			margin-bottom: 0;
		}
	}

	.imageText.minMargin {
		margin: 0 0 2.5rem 0
	}

	.imageText.maxMargin {
		margin: 0 0 5rem 0;
	}

	.imageText {
		margin: 0 0 5rem 0;

		.content .image {
			width: 75%;
		}
	}
}

@media (min-width: 900px) {
	.imageText.noMargin {
		margin: 0 0 0 0;

		.content {
			margin-bottom: 0;
		}
	}
	
	.imageText .content {
		flex-direction: row;
		justify-content: center;
		margin-bottom: 4rem;
		
		.text {
			margin: 0 0 0 1.5rem;
			width: 48%;
		}

		.image {
			margin: 0 1.5rem 0 0;
			width: 48%;
		}
	}
}

@media (min-width: 1024px) {
	.imageText {
		margin: 0 0 7rem 0;

		.content h2 {
			margin-bottom: 5rem;
		}
	}

	.imageText.noBottomPadding .content .text {
		padding: 0 0 0 0;
	}
	
	.imageText.noMargin {
		margin: 0 0 0 0;

		.content {
			margin-bottom: 0;
		}
	}

	.imageText.minMargin {
		margin: 0 0 3.5rem 0
	}

	.imageText.maxMargin {
		margin: 0 0 7rem 0;
	}
}

@media (min-width: 1200px) {
	.imageText {
		margin: 0 0 10rem 0;
	}

	.imageText.noBottomPadding .content .text {
		padding: 0 0 0 0;
	}
	
	.imageText.noMargin {
		margin: 0 0 0 0;

		.content {
			margin-bottom: 0;
		}
	}

	.imageText.minMargin {
		margin: 0 0 5rem 0
	}

	.imageText.maxMargin {
		margin: 0 0 10rem 0;
	}
}

@media (min-width: 1400px) {
	.imageText .content {
		.image,
		.text {
			width: 40%;
		}

		p {
			margin-top: 3rem;
		}
	}
}

@media (min-width: 1500px) {
	.imageText.noBottomPadding .content .text {
			padding: 0 0 0 0;
	}

	.imageText.noMargin {
		margin: 0 0 0 0;

		.content {
			margin-bottom: 0;
		}
	}

	.imageText.minMargin {
		margin: 0 0 6.5rem 0;
	}

	.imageText,
		.imageText.maxMargin {
			margin: 0 0 13rem 0;
	}
}
/**********************************
* Flexnav Slide Stylings
**********************************/

ul.flexnav {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    transition: none;
    -webkit-transform-style: preserve-3d; // stop webkit flicker
    max-height: 0;
    overflow: hidden;

    &.opacity {
        opacity: 0;
    }

    &.flexnav-show {
        max-height: 2000px;
        opacity: 1;
        transition: all .5s ease-in-out;
    }

    /* generelle Stylings für alle Ebenen */

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li{
        a {
            display: block;
            text-decoration: none;
        }
    }
}

.slideNavWrapper {
    position: fixed;
    right: -50rem;
    top: 0;
    bottom: 0;
    background-color: white;
    padding: 1.5rem 7rem 1.5rem 1.5rem;
    opacity: 0;
    transition: all 0.2s ease;
    z-index: 1000;

    .menuButton {
        &::before{
            content: 'x';
            font-family: 'PT Sans', sans-serif;
            font-size: 2em;
        }
    }
}

.slideNavOverlay,
.slideNavButtons {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.navOpen {
    .slideNavWrapper {
        left: 0;
        right: 0;
        opacity: 1;
    }

    .slideNavOverlay {
        position: fixed;
        background-color: rgba(black, 0.8);
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 1;
        z-index: 3;
    }

    .slideNavButtons {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: black;
        padding: 1rem;
        opacity: 1;
    }

}


/************************************************
* RESPONSIVE
*************************************************/

@media all and (min-width: 400px) {

    .navOpen {
        .slideNavWrapper {
            left: auto;
            width: 40rem;
        }
    }

}

@media all and (min-width: 500px) {

    .navOpen {
        .slideNavWrapper {
            width: 50rem;
            padding: 2.5rem 8rem 2.5rem 2.5rem;
        }
    }

}

@media all and (min-width: $flexNavBreakPoint) {

    ul.flexnav {
        position: relative;
        overflow: visible;
        max-height: none;
        z-index: 2;
        @include clearfix;

        &.opacity {
            opacity: 1;
        }

        li {

        }
    }

    /* Stylings erste Ebene (nicht vererbt) */

    ul.level1 {
         > li {
            position: relative;
            float: left;
            overflow: visible;

            > a {

            }
        }
    }

    /* Stylings zweite Ebene (nicht vererbt) */

    ul.level2 {
        position: absolute; 
        top: auto;
        left: 0;
        display: none;
        
        > li {
            position: relative;
            > a {
                white-space: nowrap;
            }
        }
    }

    /* Stylings dritte Ebene (nicht vererbt) */

    ul.level3 {
        position: absolute; 
        top: 0;
        left: 100%;
        display: none;
        
        > li {
            position: relative;
            a {
                white-space: nowrap;
            }
        }
    }

    .menuButton {
        display: none;
    }
}

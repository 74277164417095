/************************************************
* FEATUREBOXES
*************************************************/

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.featureBoxes {
		margin-top: 15rem;
		padding: 0 0 3rem 0;
	}

	a.feature {
		width: 100%;
		display: block;
		position: relative;
		margin-bottom: 1.2rem;

		.background {
			background-size: cover;
			background-position: center;
         	transition: transform 1s, filter 1s;


			&::before {
				content: '';
				/* background:#000; */
				opacity: .2;
				transition: opacity 400ms ease-in;
				padding-top: 58%;
				display: block;
			}

		}

		&.hide {
			opacity: 0;
		}

		&.fadeIn-active {
			animation: fadeIn 1.5s forwards;
		}
	}

	a.feature .text {
		color: white;
		text-decoration: none;
		position: absolute;
		bottom: 2rem;
		left: 2rem;
		right: 2rem;
		font-family: 'Playfair Display', serif;
		font-size: 1.9rem;
	    line-height: 1.5;
	    transition: opacity .3s ease-in;
	    padding: 0;

	    span {
			display: block;
			font-family: 'PT Sans', sans-serif;
			font-size: 1.6rem;
			text-transform: uppercase;
	    }
	}


/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 600px) {
		.feature .text {
			font-size: 1.9rem;
		}
	}

	@media (min-width: 700px) {
		.featureBoxes {
			padding: 0 0 5rem 0;
		}
	}

	@media (min-width: 800px) {
		.featureBoxes .wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		a.feature {
			margin-bottom: 1rem;

			.background::before {
				padding-top: 0;
				min-height: 40rem;
			}

			&.feature:nth-of-type(1),
			&.feature:nth-of-type(2),
			&.feature:nth-of-type(9),
			&.feature:nth-of-type(10),
            &.feature:nth-of-type(17),
            &.feature:nth-of-type(18) {
				width: 49.5%;
			}

			&.feature:nth-of-type(3),
			&.feature:nth-of-type(11) {
				width: 65%;
			}

			&.feature:nth-of-type(4),
			&.feature:nth-of-type(12) {
				width: 34%;
			}

			&.feature:nth-of-type(5),
			&.feature:nth-of-type(8),
            &.feature:nth-of-type(13),
            &.feature:nth-of-type(16) {
				width: 40%;
			}

			&.feature:nth-of-type(6),
     		&.feature:nth-of-type(7),
            &.feature:nth-of-type(14),
            &.feature:nth-of-type(15) {
				width: 59%;
			}

			// &.feature:nth-of-type(12) {
			// 	width: 32.666667%;
			// }

			&.fadeIn-active:nth-of-type(2n) {
				animation: fadeIn 2s .25s forwards;
			}

			&.fadeIn-active:nth-of-type(9),
			&.fadeIn-active:nth-of-type(11) {
				animation: fadeIn 2s .5s forwards;
			}

			&.fadeIn-active:nth-of-type(12) {
				animation: fadeIn 2s .75s forwards;
			}
		}
	}

	@media (min-width: 1024px) {
		.featureBoxes {
			padding: 0 0 7rem 0;
		}

		a.feature .text {
			font-size: 2.1rem;
		}
	}

	@media (min-width: 1025px) {
		a.feature{
			overflow: hidden;
			&:hover {
				.background {
		        	transform: scale(1.1) rotate(2deg);
		        	filter: blur(1px);
					&::before {
						opacity: .4;
					}
		        }

			}
		}

		a.feature:hover .text {
			// opacity: 0;
		}
	}

	@media(min-width: 1200px) {
		.featureBoxes {
			padding: 0 0 10rem 0;
		}
	}

	@media (min-width: 1250px) {
		a.feature .text {
			font-size: 2.3rem;
			bottom: 2.5rem;
			left: 2.5rem;
			right: 2.5rem;
		}

		a.feature .background::before{
			min-height: 45rem;
		}
	}

	@media (min-width: 1500px) {
		.featureBoxes {
			padding: 0 0 13rem 0;
		}

		a.feature {
			margin-bottom: 1.5rem;

			.text {
				font-size: 2.5rem;
			}
		}
	}

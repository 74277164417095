
/************************************************
* FONT
*************************************************/

  // in page Footer
  //@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700|Playfair+Display');

/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans'), local('PTSans-Regular'),
       url('/fileadmin/networker.de/fonts/pt-sans-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fileadmin/networker.de/fonts/pt-sans-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold'), local('PTSans-Bold'),
       url('/fileadmin/networker.de/fonts/pt-sans-v9-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fileadmin/networker.de/fonts/pt-sans-v9-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* playfair-display-regular - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'),
       url('/fileadmin/networker.de/fonts/playfair-display-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fileadmin/networker.de/fonts/playfair-display-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}



/************************************************
* Base Font Setting
*************************************************/

  html{
    font-size: 62.5%; // 1 rem = 10px
  }

  body{
    font-family: 'PT Sans', sans-serif;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

/************************************************
 * TYPO
 ************************************************/

  h1 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    @include fluid-type($min_width, $max-width, 25px, 60px);
    line-height: 1.5;
    color: white;
  }

  h2 {
    font-family: 'Playfair Display', serif;
    font-size: 1.9rem;
    line-height: 1.5;
  }

  h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.9rem;
    line-height: 1.5;
  }

  p {
    font-family: 'PT Sans', sans-serif;
    font-size: 1.9rem;
    line-height: 1.5;
    margin: 0 0 1.5em;
  }

  a {

    &:hover {

    }

  }

/************************************************
* RESPONSIVE
*************************************************/

  @media (min-width: 1024px) {
    h2 {
      font-size: 2.4rem;
    }

    h3 {
      font-size: 2.1rem;
    }
  }


  @media (min-width: 1250px) {
    h2 {
      font-size: 2.7rem;
    }

    h3 {
      font-size: 2.3rem;
    }
  }

  @media (min-width: 1500px) {
    h2 {
      font-size: 3rem;
    }

    h3 {
      font-size: 2.5rem;
    }
  }

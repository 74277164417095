/************************************************
* TEXTSTANDARD
*************************************************/

	.textStandard {
		padding: 2rem 0;

		span {
			font-weight: 700;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				list-style-type: none;
				font-size: 1.8rem;
    		line-height: 1.5;
    		margin-bottom: 1rem;
    		padding-left: 1.5rem;
    		position: relative;

    		&::before {
					@extend %icons;
					content: '\e803';
					position: absolute;
					left: -.5rem;
    		}
			}
		}

		h3 {
			margin: 2.5rem 0 0 0;
		}

		a {
			color: $brandPrimary;
			text-decoration: none;
			font-size: 1.8rem;
		}
	}



/************************************************
* RESPONSIVE
*************************************************/

	@media (min-width: 600px) {
		.textStandard ul li,
		.textStandard a {
			font-size: 1.7rem;
		}
	}

	@media (min-width: 110em) {
		.textStandard {
			max-width: 100em;
			margin: 0 auto;
		}
	}

	@media (min-width: 1024px) {
		.textStandard a:hover {
			border-bottom: 1px solid $brandPrimary;
		}
	}

	// @media (min-width: 1200px) {
	// 	.textStandard {
	// 		margin: 0 15%;
	// 	}
	// }

	// @media (min-width: 1350px) {
	// 	.textStandard {
	// 		margin: 0 20%;
	// 	}
	// }

	// @media (min-width: 1500px) {
	// 	.textStandard {
	// 		margin: 0 25%;
	// 	}
	// }

	// @media (min-width: 1700px) {
	// 	.textStandard {
	// 		margin: 0 30%;
	// 	}
	// }
